import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { HeaderMedium500Style,HeaderMedium600Style,InfoMedium600Style,InfoMedium500Style } from '../../styles/TextStyles';


export const DataVariablePresentation = css`
    ${HeaderMedium500Style}
   
`;

export const DataVariableLabel = css`
    ${InfoMedium600Style}
   
`;

export const DataValueLabel = css`
    ${InfoMedium500Style}
   
`;