import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {TextMediumMini500Style} from '../../styles/TextStyles';
export const ImagePanel = styled.div`
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: 8px;
    padding: 1rem;
`;

export const ImagePanelHeader = styled.div`
    font-weight: 500;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${theme.colors.backgroundDark};
`;

export const DragZone = styled.div`
    background-color: ${props => props.isDragging ? '#f5f5f5' : '#ffffff'};
    border: 2px dashed ${props => props.isDragging ? theme.colors.primary : theme.colors.backgroundDark};
    border-radius: 6px;
    
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    min-height: 12rem;  // Changed from 100px to fixed height
    height: 12rem;      // Added fixed height
    padding: 1rem;      // Added padding
    box-sizing: border-box; // Added to include padding in height calculation
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &:hover {
        border-color: ${theme.colors.primary};
    }

    .rs-uploader-trigger-btn, 
    .rs-uploader-trigger {
        width: 200px;
        height: 100%;  // Make it take full height
        display: flex;
        justify-content: center;
    }
`;

export const PreviewImage = styled.img`
    display: block;
    width: auto;        
    height: 100%;       
    max-width: 100%;    
    margin: 0 auto;
    margin-bottom: 1rem;
    object-fit: contain;
`;

export const UploadInstructions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 2rem; 
    height: 5rem;
    justify-content: center; // Center content vertically
`;

export const UploadIcon = styled(FontAwesomeIcon)`
    font-size: 5em;
    margin-bottom: 16px;
    color: ${theme.colors.backgroundDark};
`;

export const UploadText = styled.p`
    ${TextMediumMini500Style}
    color: ${theme.colors.backgroundVeryDark};
    margin-bottom: 1rem;
`;
