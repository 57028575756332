/**
 * CreatorAccordion which inheris from BaseAccordion
 * The main differences between all inherited accordions is applied styling and the header rendering.The main differences between all inherited accordions is the styling and the header rendering
 * This is used for "add new user", ment to be nested inside other accordions
 */

import React, { forwardRef } from 'react';
import BaseAccordion from './BaseAccordion';
import { ReactComponent as PlusCircle } from '../../assets/icons/plusCircle.svg';
import { HeaderLarge500Style } from '../styles/TextStyles';

import {
  AccordionContainer,
  PanelContainer,
  DynamicHeader,
  IconContainer,
  IconWrapper,
  PanelContent
} from './styled_sub_comps/CreatorAccordionStyledSubComps';

const CreatorAccordion = ({ children, ...props }) => (
  <AccordionContainer>
    <BaseAccordion {...props}>
      {children}
    </BaseAccordion>
  </AccordionContainer>
);

/**
 * Panel subcomponent for CreatorAccordion
 * Renders a header with text and animated plus icon
 * Content is wrapped in PanelContent for consistent spacing
 */
const Panel = forwardRef(({ header, children, ...props }, ref) => (
  <PanelContainer>
    <BaseAccordion.Panel
      {...props}
      ref={ref}
      renderHeader={({ isActive, onToggle }) => (
        <DynamicHeader isActive={isActive} onClick={onToggle}>
          <span css={HeaderLarge500Style}>{header}</span>
          <IconContainer isActive={isActive}>
            <PlusCircle />
          </IconContainer>
        </DynamicHeader>
      )}
    >
      <PanelContent>
        {children}
      </PanelContent>
    </BaseAccordion.Panel>
  </PanelContainer>
));

CreatorAccordion.Panel = Panel;
export default CreatorAccordion;