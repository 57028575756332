import React, { useEffect, useState } from "react";
import { DateRangePicker, SelectPicker, Container, Panel, Button, Text, HStack, VStack, Input, FlexboxGrid, InputGroup, Table, Checkbox, IconButton } from "rsuite";
import format from 'date-fns/format';
import {prognosisOptions} from '../../utils/dateRanges';
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../Notification";
import SearchIcon from '@rsuite/icons/Search';
import VisibleIcon from '@rsuite/icons/Visible';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CollapsedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import { useProjectContext } from "../../context/ProjectContext";
import StyledSectionTitle from "../ui_reusable/SectionTitle";
import {IconDanger, IconSuccess, IconWarning} from "../../utils/icons";
import {TagDefault} from "../../utils/tag";
import ShowDataset from "../datasets/Show";
import { aggregationMethodOptions, intervals } from "../../utils/dropdowns";

const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';

const ModelBuild = ()=>{
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const {selectedProject} = useProjectContext();   
    const {solutionId} = location.state || {};
    const [projectSolution, setProjectSolution] = useState(null);
    const [projectSolutionDrivers, setProjectSolutionDrivers] = useState([]);
    const [timeframe, setTimeframe] = useState({
        from: '',
        to: '',
        prognosis: 0
    });
    const [parameters, setParameters] = useState({
        business_goal: '',
        dependent_driver: '',
        frequency: '',
        aggregation_method: ''
    });
    const [dependentDriver, setDependentDriver] = useState(null);
    const [independentDrivers, setIndependentDrivers] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    
    useEffect(()=>{
        const getProjectSolution = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject?.id}/solutions/${solutionId}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProjectSolution(data);
            setTimeframe(prev => ({ ...prev, from: new Date(data.timeframe.from).toISOString().split('T')[0], to: new Date(data.timeframe.to).toISOString().split('T')[0] }));
        }
        const getProjectSolutionDrivers = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject?.id}/solutions/${solutionId}/drivers?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setDependentDriver(res.data.find(e=>e.is_required));
            setProjectSolutionDrivers(res.data);
        }
        getProjectSolution();
        getProjectSolutionDrivers();
    },[selectedProject, solutionId]);

    const handleDateRange = (value) =>{
        if(value){
            const [from, to] = value;
            if(from && to){
                const yearsDifference = to.getFullYear() - from.getFullYear();
                if(yearsDifference < 2){
                    setError('Select at least two years.')
                    setShowNotification(true);
                } else {
                    setTimeframe(prev => ({ ...prev, from: new Date(from).toISOString().split('T')[0], to: new Date(to).toISOString().split('T')[0] }));
                }
            }
        } else {
            setTimeframe(prev => {
                const { from: _, to: __, ...rest } = prev;
                return rest;
            });
        }
    }

    const handleToggleSelect = (value) => {
        if(dependentDriver){
            if(value === "all"){
                if(!selectAll){
                    setSelectAll(true);
                    setIndependentDrivers([]);
                } else {
                    setSelectAll(false);
                    setIndependentDrivers(projectSolutionDrivers.filter(elem=>elem.id!==dependentDriver.id));
                }
            } else {
                if(independentDrivers.find(e=>e.id===value)){
                    setIndependentDrivers(independentDrivers.filter(elem=>elem.id!==value));
                } else {
                    setIndependentDrivers([...independentDrivers, projectSolutionDrivers.find(e=>e.id===value)]);
                }
            }
        }
    }

    const renderRowExpanded = (rowData) => {
        return (
            <ShowDataset datasetId={rowData.driver.id} units={rowData.driver.units}/>
        );
    };

    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });
    
        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
    
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const handleRun = () =>{
        const payload = {
            timeframe, 
            parameters, 
            dependent_driver_id: dependentDriver.driver.id, 
            independent_drivers: independentDrivers.map(elem=>{ 
                return {
                    driver_id: elem.driver.id
                }
            })
        };
        console.log(payload);
        // navigate('/report');
    }

    return (
        <Container style={{ margin: "20px 0" }}>
            {
                projectSolution && <Panel>
                    {
                        showNotification && <Notification variant='danger' message={error} onClose={() => setShowNotification(false)} duration={3000} />
                    }
                    {
                        projectSolution && <div><StyledSectionTitle>{projectSolution.solution.name} model build</StyledSectionTitle></div>
                    }
                    <Panel header="Set Timeframe" style={{ marginBottom: "10px" }} bordered>
                        <VStack spacing={20} style={{ margin: "20px"}}>
                            <HStack spacing={20}>
                                <Text>Interval: </Text>
                                {
                                    <DateRangePicker 
                                        // ranges={quickSelectionDateOptions(selectedDataset.date_start, selectedDataset.date_end)}
                                        format="yyyy-MM-dd"
                                        renderValue={([start, end]) => {
                                            return format(start, 'EEE, d MMM Y') + ' - ' + format(end, 'EEE, d MMM Y');
                                        }}
                                        // shouldDisableDate={(date)=>{
                                        //     return (date < new Date(`${projectSolution.date_start} 00:00:00`) || date > new Date(`${selectedDataset.date_end} 23:59:00`))
                                        // }}
                                        value={timeframe?[new Date(timeframe?.from), new Date(timeframe?.to)]:''}
                                        onChange={handleDateRange}
                                        cleanable={false}
                                    />
                                }
                            </HStack>
                            <HStack>
                                <Text>Prognosis:</Text>
                                <SelectPicker searchable={false} defaultValue={0} data={prognosisOptions} onChange={(value)=>setTimeframe(prev=>({...prev, prognosis: value}))} style={{ minWidth: 224}} value={timeframe?.prognosis} cleanable={false}/>
                            </HStack>
                        </VStack>
                    </Panel>
                    <Panel header="Set parameters" style={{ marginBottom: "10px" }} bordered>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={4} style={{ textAlign: 'center' }}>I want to</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <Text>Select business goal</Text>
                                <SelectPicker searchable={false} cleanable={false} data={[]} onChange={(value)=>setParameters(prev=>({...prev, business_goal: value}))} style={{ width: "220px" }}/>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <Text>Select dependent variable</Text>
                                <SelectPicker searchable={false} cleanable={false} data={projectSolutionDrivers.map((projectSolutionDriver, i)=>{ return {key: i, label: projectSolutionDriver.driver.name, value: projectSolutionDriver.id}})} onChange={(value)=>setParameters(prev=>({...prev, dependent_driver: value}))} style={{ width: "220px" }}/>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <br/>
                        <br/>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={4} style={{ textAlign: 'center' }}>Model data frequency</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <SelectPicker searchable={false} cleanable={false} data={intervals} defaultValue="monthly" onChange={(value)=>setParameters(prev=>({...prev, frequency: value}))} style={{ width: "220px" }}/>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>Aggregation method</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <SelectPicker searchable={false} cleanable={false} data={aggregationMethodOptions} defaultValue="mean" onChange={(value)=>setParameters(prev=>({...prev, aggregation_method: value}))} style={{ width: "220px" }}/>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                    <Panel header="Select drivers" bordered>
                        <HStack style={{ marginBottom: "20px" }}>
                            <Text>Dependent variable</Text>
                            <SelectPicker searchable={false} cleanable={false} data={projectSolutionDrivers.map((projectSolutionDriver, i)=>{ return {key: i, label: projectSolutionDriver.driver.name, value: projectSolutionDriver.id}})} style={{minWidth: 224}} onChange={(value)=>setDependentDriver(projectSolutionDrivers.find(e=>e.id===value))} value={dependentDriver?.id}/>
                        </HStack>
                        <Panel bordered>
                            <div className="show-grid mb-10">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item colspan={4}><Text weight="bold">Available drivers: {projectSolutionDrivers.length||0}</Text></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={4}><TagDefault label={`${independentDrivers.length} drivers selected`}/></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={6}>
                                        <InputGroup>
                                            <Input placeholder="Find driver" />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                            <Table 
                                data={projectSolutionDrivers} 
                                height={400} 
                                rowHeight={52} 
                                autoHeight 
                                rowKey={rowKey}
                                expandedRowKeys={expandedRowKeys}
                                renderRowExpanded={renderRowExpanded}
                                rowExpandedHeight={650}
                                onRowClick={() => {
                                    // console.log(data);
                                }}
                            >
                                <Column flexGrow={4}>
                                    <HeaderCell><Checkbox color="violet" onChange={()=>handleToggleSelect("all")}>Select all/none</Checkbox></HeaderCell>
                                    <Cell>{rowData=>
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item colspan={8}><Checkbox onChange={()=>handleToggleSelect(rowData.id)} color="violet" disabled={dependentDriver && rowData.id===dependentDriver.id} checked={!!independentDrivers.find(elem=>elem.id===rowData.id)}>{rowData.driver.name}</Checkbox></FlexboxGrid.Item>
                                            {
                                                dependentDriver && rowData.id===dependentDriver.id && <FlexboxGrid.Item colspan={6}><TagDefault label="Dependent"/></FlexboxGrid.Item>
                                            }
                                        </FlexboxGrid>}
                                    </Cell>
                                </Column>
                                <Column flexGrow={1} sortable>
                                    <HeaderCell>Source</HeaderCell>
                                    <Cell>{rowData=>rowData.driver.source}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Last Update</HeaderCell>
                                    <Cell>{rowData=>new Date(rowData.driver.updated_at).toLocaleDateString()}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Observations</HeaderCell>
                                    <Cell>{rowData=>rowData.driver.observations}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Units</HeaderCell>
                                    <Cell>{rowData=>rowData.driver.units}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Quality check</HeaderCell>
                                    <Cell>
                                        {
                                            rowData=>{
                                                if(rowData.driver.quality==="good"){
                                                    return <IconSuccess/>
                                                }
                                                else if(rowData.driver.quality==="check"){
                                                    return <IconWarning/>
                                                }
                                                else if(rowData.driver.quality==="poor"){
                                                    return <IconDanger/>
                                                }
                                            }
                                        }
                                    </Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell>View</HeaderCell>
                                    <Cell>{rowData=>
                                        <IconButton
                                        appearance="subtle"
                                        onClick={() => {
                                            handleExpanded(rowData);
                                        }}
                                        icon={
                                        expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                                            <CollapsedOutlineIcon />
                                        ) : (
                                            <VisibleIcon />
                                        )
                                        }
                                    />}</Cell>
                                </Column>
                            </Table>
                        </Panel>
                    </Panel>
                    <Panel>
                        <div style={{ textAlign: "right" }}>
                            <Button appearance="primary" color="green" size="lg" onClick={handleRun} startIcon={<CheckOutlineIcon/>}>Run</Button> 
                        </div>
                    </Panel>
                </Panel>
            }
        </Container>
    );
}
export default ModelBuild;