import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { TextLarge500Style, InfoMediumMaxi500Style} from '../../styles/TextStyles';
import { DataValueLabel } from './DataInfoUtils';

export const standardCheckboxStyle = css`
  appearance: none;
  min-height: 1rem;
  max-height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${theme.colors.primary};
  border-radius: 0.1rem;
  background-color: ${theme.colors.primaryVeryLight};
  cursor: pointer;
  position: relative;
  margin-top: 0.3rem;
  border-radius: 0.2rem;
  
  &:hover {
    border-color: ${theme.colors.primaryDark};
    background-color: ${theme.colors.primaryLight};
  }

  &:checked {
    background-color: ${theme.colors.primary};
    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:hover {
      background-color: ${theme.colors.primaryDark};
    }
  }
`;

export const standardInputStyle = ({ highlightError }) => css`
    ${DataValueLabel}
    width: 100%;
    padding: 0.5rem;
    border: 1px solid ${highlightError ? theme.colors.error : theme.colors.backgroundDark};
    border-radius: 4px;
    background: transparent;
    transition: border-color 0.2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    

    &:focus {
        outline: none;
        border-color: ${highlightError ? theme.colors.error : theme.colors.primary};
    }

    &:disabled {
        background: transparent;
        border-color: transparent;
        cursor: default;
    }
`;
