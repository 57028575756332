import React, { useEffect, useState } from "react";
import { Button, Container, Panel, Table } from "rsuite";
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import EditIcon from "@rsuite/icons/Edit";
import ConversionIcon from '@rsuite/icons/Conversion';
import PageIcon from '@rsuite/icons/Page';
import TrashIcon from '@rsuite/icons/Trash';
import { TagDanger, TagDefault } from "../../utils/tag";
import { TagSuccess, TagWarning } from "../ui_reusable/styled_comps/Tags";

const { Column, HeaderCell, Cell } = Table;

const ModelList = () => {
    const [artifacts, setArtifacts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        const fetchArtifacts = async () => {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_URL}/api/artifacts`;
            const response = await fetch(url, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const result = await response.json();
                setLoading(false);
                setArtifacts(result.data);
            }
        }
        fetchArtifacts();
    },[]);

    return (
        <Container style={{ margin: "20px" }}>
            <Panel header="My models" bordered>
                {/* {
                    datasetList.length > 0 && <div className="text-end"><SelectPicker searchable={false} defaultValue={0} size="sm" data={datasetList.map((elem, i)=>{return{key:i+1, label: elem.name, value: elem.id}})} onChange={(value)=>setSelectedDataset(value)} style={{ minWidth: 224}} value={selectedDataset}/></div>
                } */}
                <Table
                    data={artifacts}
                    loading={loading}
                    height={52}
                    autoHeight
                >
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name"></Cell>
                    </Column>
                    <Column flexGrow={1} align="center">
                        <HeaderCell>Note</HeaderCell>
                        <Cell>{()=><FileDownloadIcon/>}</Cell>
                    </Column>
                    <Column sortable flexGrow={1} align="center">
                        <HeaderCell>Solution</HeaderCell>
                        <Cell>{rowData=><TagDefault label={rowData.solution.name}/>}</Cell>
                    </Column>
                    <Column sortable flexGrow={1} align="center">
                        <HeaderCell>Last run date</HeaderCell>
                        <Cell>{rowData=>new Date(rowData.run_at).toLocaleDateString()}</Cell>
                    </Column>
                    <Column sortable flexGrow={1} align="center">
                        <HeaderCell>Last fit score</HeaderCell>
                        <Cell>
                            {
                                rowData=>{
                                    if(rowData.fit_score==="good"){
                                        return <TagSuccess label={rowData.fit_score}/>
                                    }
                                    else if(rowData.fit_score==="moderate"){
                                        return <TagWarning label={rowData.fit_score}/>
                                    }
                                    else if(rowData.fit_score==="poor"){
                                        return <TagDanger label={rowData.fit_score}/>
                                    }
                                }
                            }
                        </Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Created by</HeaderCell>
                        <Cell>{rowData=>rowData.created_by.name}</Cell>
                    </Column>
                    <Column flexGrow={2}>
                        <HeaderCell>View/Run/Report/Delete</HeaderCell>
                        <Cell>{()=><>
                        <Button appearance="subtle" title="Modify and run"><EditIcon/></Button>
                        <Button appearance="subtle" title="Re-run"><ConversionIcon/></Button>
                        <Button appearance="subtle"><PageIcon/></Button>
                        <Button appearance="subtle" title="Delete"><TrashIcon/></Button>
                        </>}</Cell>
                    </Column>
                </Table>
            </Panel>
        </Container>
    );
}
export default ModelList;