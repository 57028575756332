import React, { useState, useCallback, useEffect } from "react";
import { Message } from "rsuite";
import useStandardToaster from '../../ui_reusable/StandardToaster';
import ImageUploader from '../../ui_reusable/ImageUploader';
import { TwoColumnGridSimpleTextDisplay } from '../../ui_reusable/styled_comps/PlacementMisc';
import {
    SettingsContainer,
    SettingsGrid,
    InfoSection,
    LogoSection,
} from './styled_sub_comps/SettingsStyledSubComps';

const ClientSettings = ({client}) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const { pushToast } = useStandardToaster();

    // Set initial data when client prop changes
    useEffect(() => {
        // Clear previous state first
        setPreviewUrl(null);
        setFileList([]);

        // Only set new values if client has a logo
        if (client?.logo) {
            const logoUrl = `${process.env.REACT_APP_API_URL}/${client.logo}`;
            setPreviewUrl(logoUrl);
            setFileList([{
                name: client.logo.split('/').pop(),
                fileKey: 1,
                url: logoUrl,
                status: 'finished'
            }]);
        }
    }, [client]);

    const handleFileSelect = useCallback((files) => {
        if (!files || !files.length) return;
        
        const file = files[files.length - 1];
        
        if (!file.blobFile.type.startsWith('image/')) {
            pushToast(
                <Message type="error">Please upload an image file</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            return;
        }

        // Clean up old preview URL if exists
        if (previewUrl && !previewUrl.includes(process.env.REACT_APP_API_URL)) {
            URL.revokeObjectURL(previewUrl);
        }
        
        const objectUrl = URL.createObjectURL(file.blobFile);
        setPreviewUrl(objectUrl);
        setFileList([file]);

        // Handle the file upload
        handleLogoUpload(file.blobFile);
    }, [previewUrl]);

    const handleRemove = useCallback(async (file) => {
        if (!client?.id) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${client.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ logo: null })
            });

            if (response.ok) {
                if (previewUrl && !previewUrl.includes(process.env.REACT_APP_API_URL)) {
                    URL.revokeObjectURL(previewUrl);
                }
                setPreviewUrl(null);
                setFileList([]);
                pushToast(
                    <Message type="success">Logo removed successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to remove logo');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to remove logo</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            console.error('Failed to remove logo:', error);
        }
    }, [client?.id, previewUrl, pushToast]);

    const handleLogoUpload = async (file) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('logo', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${client.id}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.ok) {
                pushToast(
                    <Message type="success">Logo updated successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update logo</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            console.error('Failed to upload logo:', error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <SettingsContainer>
            { client && 
                <SettingsGrid>
                    <InfoSection>
                        <TwoColumnGridSimpleTextDisplay>
                            <span>Client name:</span>
                            <span>{client.name}</span>
                            <span>Client ID:</span>
                            <span>{client.client_id}</span>
                            <span>KAM:</span>
                            <span>{client.kam}</span>
                        </TwoColumnGridSimpleTextDisplay>
                    </InfoSection>

                    <InfoSection>
                        <TwoColumnGridSimpleTextDisplay>
                            <span>Contact:</span>
                            <span>{client.contact?.name || 'Not set'}</span>
                            <span>E-mail:</span>
                            <span>{client.contact?.email || 'Not set'}</span>
                            <span>Phone:</span>
                            <span>{client.contact?.phone || 'Not set'}</span>
                        </TwoColumnGridSimpleTextDisplay>
                    </InfoSection>

                    <LogoSection>
                        <ImageUploader
                            onFileSelect={handleFileSelect}
                            onRemove={handleRemove}
                            previewUrl={previewUrl}
                            fileList={fileList}
                            uploading={uploading}
                            headerText="Logo"
                        />
                    </LogoSection>
                </SettingsGrid>
            }
        </SettingsContainer>
    );
}

export default ClientSettings;