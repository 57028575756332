import styled from '@emotion/styled';
import { Input } from 'rsuite';
import theme from '../../styles/theme';
import { TextLarge500Style } from '../styles/TextStyles';
import { circleButtonStyle } from '../ui_reusable/styled_comps/Buttons'; // Updated import
import {CommonFieldStyles} from '../styles/ProfileStyles';
import ProfileSymbol from '../../assets/icons/profile.svg';
import { ThreeColumnGrid } from '../ui_reusable/styled_comps/PlacementMisc';

export const Container = styled.div`
  margin: 60px;
`;

export const TwoColumnsInPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .input-group {
    margin-bottom: 1rem;
  }

  .d-block {
    color: ${theme.colors.error};
    margin-top: 0.5rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FieldContainer = styled.div`
  width: 4rem;
  min-width: 200px;
  max-width: 300px;
  height: 32px;
`;

export const StyledPlaintext = styled.div`
  ${CommonFieldStyles}
  display: flex !important;
  align-items: center !important;

  & .rs-plaintext {
    margin: 0 !important;
    padding: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

export const StyledInput = styled(Input)`
  ${CommonFieldStyles}
`;

export const InputWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;

  .rs-input,
  .rs-plaintext {
    width: 100% !important;
    height: 32px !important;
    padding: 5px 11px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    box-sizing: border-box !important;
  }

  .rs-plaintext {
    display: flex !important;
    align-items: center !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

//Expecting props: avatarUrl in the parent component
export const AvatarStylizedContainer = styled.div`
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  flex: 0 0 2.8rem;
  border-radius: 50%;
  padding: 0;
  margin-right: 2rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1rem solid white;
  background-image: url(${props => props.avatarUrl? props.avatarUrl : ProfileSymbol});
  background-size: ${props => props.avatarUrl ? '100%' : '40%'};
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${theme.colors.primaryVeryLight};
  stroke: white;
  stroke-width: 5px;
  box-shadow: 0 1.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ButtonContainerInline = styled.div`
  display: inline-block;
`;

export const PasswordPanel = styled.div`
  margin: 20px 0;
  padding: 10px;
  width: 350px;
`;

export const Button = styled.button`
    ${circleButtonStyle}
    --icon-color: ${theme.colors.standardText};
    
    &:hover {
        --icon-color: '#000'};
    }
`;
