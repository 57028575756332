/**
 * User-specific dropdown button with avatar icon.
 * Used for user-related actions like profile, settings, logout, etc.
 * Features click-outside detection for auto-closing.
 */

import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { CircleButton } from './styled_comps/Buttons'; // Updated import
import { DropdownContainer, DropdownMenu, DropdownItem, dropdownContainer, dropdownMenu, dropdownItem } from '../styles/DropdownButtonStyles';
import AvatarIconStyle from '../styles/AvatarIconStyle';

// Replace Button definition with CircleButton
const Button = CircleButton;

const DropdownButtonUser = ({ children }) => {
    // Track dropdown open/close state
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Click outside handler to auto-close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div css={dropdownContainer} ref={dropdownRef}>
            <Button onClick={()=>setIsOpen(!isOpen)} className={isOpen ? 'open' : ''}>
                <div css={AvatarIconStyle}/>
            </Button>
            <div css={dropdownMenu} className={isOpen ? 'open' : ''}>
                {React.Children.map(children, child => 
                    child && typeof child.type !== 'string'
                        ? React.cloneElement(child, { setIsOpen: setIsOpen || (() => {}) })
                        : child
                )}
            </div>
        </div>
    );
};

export default DropdownButtonUser;


