import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "rsuite";
import GearIcon from '@rsuite/icons/Gear';

import SectionTitle from "../../ui_reusable/SectionTitle";
import SimpleAccordion from "../../ui_reusable/SimpleAccordion";
import VersatileGrid from '../../ui_reusable/VersatileGrid';
import { SmallStandardButton } from '../../ui_reusable/styled_comps/Buttons';
import { TagSuccess, TagWarning } from "../../../utils/tag";
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../../ui_reusable/VersatileGridUtils';
import { VerySmallNormalTag } from "../../ui_reusable/styled_comps/Tags";

const ClientProjects = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id: clientId } = location.state || {};
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getClientProjects = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/?client=${clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const responseData = await response.json();
                setProjects(responseData?.data || []);
            } catch (error) {
                console.error('Failed to fetch projects:', error);
                setProjects([]);
            } finally {
                setLoading(false);
            }
        };
        
        if (clientId) {
            getClientProjects();
        } else {
            setLoading(false);
            setProjects([]);
        }
    }, [clientId]);

    const handleManage = (projectId) => {
        if (!projectId) return;
        
        navigate(`/projects/${projectId}`, {
            state: {
                id: projectId,
                projects: projects?.map(p => ({ id: p.id, name: p.name })) || []
            }
        });
    };

    const headers = [
        {
            title: 'Project name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Client',
            key: 'client',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Created',
            key: 'created_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Solutions',
            key: 'solutions',
            type: 'string',
            isSortable: false,
            isSearchable: true,
            getValue: (rowData) => rowData.solutions.join(' ')
        },
        {
            title: 'Status',
            key: 'status',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'KAM',
            key: 'kam',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: '',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false
        }
    ];

    const renderRow = (rowData,rowIdx) => [
        <GridCellBoldText>{rowData.name}</GridCellBoldText>,
        <GridCellNormalText>{rowData.client}</GridCellNormalText>,
        <GridCellSmallFadedText>
            {new Date(rowData.created_at).toLocaleDateString()}
        </GridCellSmallFadedText>,
        <GridCellNormalText>
            {rowData.solutions.length > 0 ? (
                rowData.solutions.map((solution, index) => (
                    <VerySmallNormalTag key={index}>{solution}</VerySmallNormalTag>
                ))
            ) : (
                <GridCellSmallFadedText>No solutions</GridCellSmallFadedText>
            )}
        </GridCellNormalText>,
        <GridCellNormalText>
            {rowData.status === 'active' ? (
                <TagSuccess label={rowData.status} />
            ) : (
                <TagWarning label={rowData.status} />
            )}
        </GridCellNormalText>,
        <GridCellSmallFadedText>{rowData.kam || 'N/A'}</GridCellSmallFadedText>,
        <SmallStandardButton
            onClick={() => handleManage(rowData.id)}
            startIcon={<GearIcon />}
        >
            Manage
        </SmallStandardButton>
    ];

    return (


                    <VersatileGrid
                        headers={headers}
                        data={projects}
                        renderRow={renderRow}
                        maxDisplayedItems={8}
                        loading={loading}
                    />
       
    );
};

export default ClientProjects;
