import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { circleButtonStyle } from '../ui_reusable/styled_comps/Buttons'; // Updated import
import citrusAppLogo from '../../assets/citrus-logo.png';
import CompanyUserLogo from '../../assets/coop.png';
import { InfoSmall500Style } from '../styles/TextStyles';

export const WhiteNavbar = styled('div')`
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    padding: 0.5rem;
    border-radius: 1.2rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 1.8rem;
    container: WhiteNavbar / inline-size;
`;

export const LeftAlignedContainer = styled.div`
    flex-grow: 1;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: left;
    
`;

export const MiddleAlignedContainer = styled.div`
    flex-grow: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    gap: 2rem;
    //margin-right: clamp(0.0rem, 10%, 100%);
    //container: MiddleAlignedContainer / inline-size;

    
    @container WhiteNavbar (max-width: 1060px) {
        gap: 1rem;
    }
    @container WhiteNavbar (max-width: 940px) {
        gap: 0rem;
    }
    @container WhiteNavbar (max-width: 870px) {
        display: inline-block;
    }
  
`;

export const RightAlignedContainer = styled.div`
    flex-grow: 1.0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    gap: 1rem;
    min-width: 12rem;
`;

export const AppLogo = styled(Link)`
    background-image: url(${citrusAppLogo});
    background-size: cover;
    height: 1.66rem;
    width: 6.7rem;
    margin-left: clamp(0.5rem, calc(60% - 5rem), 100%);
    margin-right: 1.2rem;
    cursor: pointer;
`;

export const IconButton = styled.button`
    ${circleButtonStyle}
`;

export const UserLogoComp = styled.div`
    background-image: url(${CompanyUserLogo});
    background-size: cover;
    height: 2.6rem;
    width: 4.53rem;
    position: relative;
`;

export const UserProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
`;

export const UserName = styled.div`
    font-size: 0.8rem;
    font-weight: bold;
`;

export const UserEmail = styled.div`
    font-size: 0.74rem;
    color: #6b7280;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-right: 0.4rem;
`;

export const ProjectListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.2rem;
    gap: 0.5    rem;
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
`;

export const ProjectListElement = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryVeryLight};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const ProjectListTitle = styled.span`
    ${InfoSmall500Style};
    padding-left: 0.6rem;
`;