import styled from '@emotion/styled';
import theme from '../../../../styles/theme';



export const UserActionsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

export const ToggleContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const RadioContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;
