import styled from '@emotion/styled';
import theme from '../../../../styles/theme';

/* Container for toggle buttons and checkboxes
 * Centers content and provides consistent spacing
 */
export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 4px;
`;

/* Statistics container for the grid header
 * Displays stats in a row with space between items
 */
export const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0;

    /* Tag container on the right side */
    > div {
        display: flex;
        gap: 8px;
        align-items: center;
    }
`;
