/**
 * BaseAccordion Component
 * 
 * The core logic of our custom accordion. This component provides smooth animation and dynamic height adjustment.
 * This component serves as the base for all accordion variants in the application.
 * 
 * Key features:
 * - Smooth height animations using react-spring
 * - Dynamic content height adjustment using ResizeObserver
 * - Support for controlled and uncontrolled states
 * - Customizable header rendering
 * - Support for non-collapsible panels
 */

import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useSpring, animated } from '@react-spring/web';

// Create a context to manage the accordion's state across components
const AccordionContext = createContext();

/**
 * Main Accordion container that manages the state of all panels.
 * @param {Object} props
 * @param {React.ReactNode} props.children - Accordion panels
 * @param {string|null} props.defaultActiveKey - Key of initially active panel
 * @param {Function} props.onToggle - Optional callback for panel state changes
 */
const BaseAccordion = ({
  children,
  defaultActiveKey = null,
  onToggle = null,
}) => {
  // State to keep track of active (expanded) panel keys
  const [activeKeys, setActiveKeys] = useState(() => {
    return defaultActiveKey ? [defaultActiveKey] : [];
  });

  // Function to handle toggling of panels
  const handleToggle = (eventKey) => {
    // Determine new active keys based on whether the clicked panel is already active
    const newActiveKeys = activeKeys.includes(eventKey)
      ? activeKeys.filter((key) => key !== eventKey) // Collapse the panel
      : [...activeKeys, eventKey]; // Expand the panel

    setActiveKeys(newActiveKeys);

    // Call external toggle handler if provided
    if (onToggle) onToggle(eventKey, newActiveKeys.includes(eventKey));
  };

  return (
    // Provide active keys and toggle function to all children via context
    <AccordionContext.Provider value={{ activeKeys, handleToggle }}>
      {children}
    </AccordionContext.Provider>
  );
};

/**
 * Individual accordion panel that handles its own animations and content rendering.
 * @param {Object} props
 * @param {string} props.eventKey - Unique identifier for the panel
 * @param {Function} props.renderHeader - Function to render custom header
 * @param {boolean} props.initClosed - Whether panel starts closed
 * @param {boolean} props.noCollapse - Whether panel can be collapsed
 * @param {React.ReactNode} props.children - Panel content
 */
const BasePanel = forwardRef(({
  eventKey = '1', // Unique identifier for the panel
  children,
  renderHeader, // Function to render the panel's header
  initClosed = false, // Determines if the panel is initially closed
  noCollapse = false, // If true, the panel cannot be collapsed
}, ref) => {
  // Access the accordion context
  const { activeKeys, handleToggle } = useContext(AccordionContext);
  const contentRef = useRef(); // Reference to the panel's content DOM element
  const observerRef = useRef(null); // Reference to the ResizeObserver
  const display = useRef(() => {
    if (initClosed) {
      return "none"; // Start closed if initClosed is true
    }
    return "block"; // Default to open when initClosed is false
  });

  // Local state to track if the panel is active (expanded)
  const [isActive, setIsActive] = useState(() => {
    if (initClosed) {
      return false; // Start closed if initClosed is true
    }
    return true; // Default to open when initClosed is false
  });

  // Custom function to handle panel toggling
  const handlePanelToggle = () => {
    if (noCollapse) return; // Prevent toggling if collapsing is disabled
    handleToggle(eventKey); // Update active keys in context
    setIsActive(!isActive); // Toggle local isActive state
  };

  // Initialize react-spring animation for height and opacity
  const [style, api] = useSpring(() => ({
    height: 0,
    opacity: 0,
    config: { tension: 200, friction: 20 },
    
  }));

   //after first render only: setting display none or block
  useEffect(() => {
    contentRef.current.style.display = initClosed ? "none" : "block";
  }, []); // Empty dependency array

  /**
   * Handles measurement and animation of panel content height.
   * Uses ResizeObserver to track content size changes and updates
   * animation accordingly.
   */
  useLayoutEffect(() => {
    if (contentRef.current) {
      // Function to measure the total height of the content, including margins
      const measureHeight = () => {
        const element = contentRef.current;
        if (!element) return 0;

        let totalHeight = element.getBoundingClientRect().height;
        // Include child margins in the total height calculation
        Array.from(element.children).forEach((child) => {
          const style = window.getComputedStyle(child);
          totalHeight +=
            parseFloat(style.marginTop) + parseFloat(style.marginBottom);
        });
        return totalHeight;
      };

      // Update the animated height when content size changes
      const updateHeight = () => {
        const height = measureHeight();
        if (isActive) {
          api.start({ 
            height, 
            immediate: false,
            onRest: () => {

              // Additional actions after fading in
            }
           });
        }
      };

      // Create a ResizeObserver to watch for content size changes
      observerRef.current = new ResizeObserver(() => {
        window.requestAnimationFrame(updateHeight);
      });

      // Observe the content element and its descendants
      observerRef.current.observe(contentRef.current);
      Array.from(
        contentRef.current.getElementsByTagName('*')
      ).forEach((element) => {
        observerRef.current.observe(element);
      });

      

      //manually setting style of contentRef with display none or block
      contentRef.current.style.display =  "block" ;


      // Set  height and opacity based on whether the panel is active
      api.start({
        height: isActive ? measureHeight() : 0,
        opacity: isActive ? 1 : 0,
        immediate: false,
        
            onRest: () => {
              contentRef.current.style.display = isActive ? "block" : "none";
              
            }
      });
    }

    // Cleanup the observer when the component unmounts or updates
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isActive, api]); // Dependencies are isActive and the animation API

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    tryCollapse: () => {
      if (isActive && !noCollapse) {
        console.log("TRY COLLAPSING");
        console.log("COLLAPSING");
        handlePanelToggle();
      }
    }
  }));

  return (
    <>
      {/* Render the header using the provided renderHeader function */}
      {renderHeader({
        isActive,
        onToggle: handlePanelToggle,
        noCollapse,
      })}
      {/* Animated div that expands and collapses the content */}
      <animated.div style={style}>
        <div  ref={contentRef}>{children}</div>
      </animated.div>
    </>
  );
});

BaseAccordion.Panel = BasePanel;
export default BaseAccordion;

