import { TextLarge500Style } from "./TextStyles";
import theme from "../../styles/theme";
import { css } from "@emotion/react";
export const CommonFieldStyles = css`
  ${TextLarge500Style}
  width: 4rem !important;
  height: 32px !important;
  padding: 5px 11px !important;
  line-height: 20px !important;
  border: 1px solid ${theme.colors.grey300} !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
`;
