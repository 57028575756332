import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { faAdd, faArrowTrendUp, faBars, faBookOpen, faBookReader, faBuilding, faDatabase, faEdit, faFile, faHistory, faList, faUser, faMagnifyingGlassArrowRight, faPlay, faProjectDiagram, faQuestion, faSignOut, faUserAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import DropdownButton, { MenuItem } from './ui_reusable/DropdownButton';
import DropdownButtonUser from './ui_reusable/DropdownButtonUser';
import LineDivider from './ui_reusable/LineDivider';
import AvatarIconStyle from './styles/AvatarIconStyle';

import {
    WhiteNavbar,
    LeftAlignedContainer,
    MiddleAlignedContainer,
    RightAlignedContainer,
    AppLogo,
    UserProfile,
    UserInfo,
    UserName,
    UserEmail,
    StyledFontAwesomeIcon,
    ProjectListContainer,
    ProjectListElement,
    ProjectListTitle
} from './styled_sub_comps/NavigationStyledSubComps';

import { Avatar } from 'rsuite';
import { useProjectContext } from '../context/ProjectContext';
import { StandardRadio, RadioProvider } from './ui_reusable/StandardRadioButtons';
import { useClientContext } from '../context/ClientContext';


//Navigation bar for whole app
const Navigation = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const { isAuthenticated, user, isAdmin, isClient, isClientManager, isClientUser } = useAuth();

    const userName = user?.name || "User name";
    const userEmail = user?.email || "Email";


    const [projects, setProjects] = useState([]);
    const {selectedProject, setSelectedProject} = useProjectContext();
    const {selectedClient} = useClientContext();

    useEffect(()=>{
        const getProjects = async (url) =>{
            const response = await fetch(url, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjects(res.data);
        }
        if(selectedClient){
            if(isAdmin || (isClient &&  isClientManager)){
                getProjects(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}`);
            } 
            else if(isClient && isClientUser){
                getProjects(`${process.env.REACT_APP_API_URL}/api/projects/my-projects/`);
            }
        }
    },[isAdmin, isClient, isClientManager, isClientUser, selectedClient]);


    return (
        <WhiteNavbar className='navbar'>
            <LeftAlignedContainer>
                 <AppLogo to="/dashboard" className="AppLogo" />
            </LeftAlignedContainer>
            {
                isAuthenticated && 
                <MiddleAlignedContainer defaultActiveKey="dashboard" appearance='default'>
                    
                    <DropdownButton title="Insights">
                        <MenuItem as={Link} to="/insights/trending"><StyledFontAwesomeIcon icon={faArrowTrendUp}/> Trending stories</MenuItem>
                        <MenuItem as={Link} to="/insights/explorer"><StyledFontAwesomeIcon icon={faMagnifyingGlassArrowRight}/> Insight explorer</MenuItem>
                        <MenuItem as={Link} to="/insights/data"><StyledFontAwesomeIcon icon={faDatabase}/> Data explorer</MenuItem>
                        <MenuItem as={Link} to="/insights/reports"><StyledFontAwesomeIcon icon={faFile}/> My reports</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/insights/feed"><StyledFontAwesomeIcon icon={faBars}/> Insight Feed</MenuItem>
                    </DropdownButton>

                    <DropdownButton title="Analyze">
                        <MenuItem as={Link} to="/models/new"><StyledFontAwesomeIcon icon={faAdd}/> New Model</MenuItem>
                        <MenuItem as={Link} to="/models"><StyledFontAwesomeIcon icon={faList}/> My models</MenuItem>
                        <MenuItem as={Link} to="/models/history"><StyledFontAwesomeIcon icon={faHistory}/> Model history</MenuItem>
                        
                        {(isAdmin || (isClient && isClientManager)) && (
                            <>
                                <LineDivider /> 
                                <MenuItem as={Link} to="/datasets"><StyledFontAwesomeIcon icon={faEdit}/> Manage drivers</MenuItem>
                            </>
                        )}
                    </DropdownButton>

                    <DropdownButton title="Scenario">
                        <MenuItem as={Link} to="/scenarios/create"><StyledFontAwesomeIcon icon={faAdd}/> Create scenario</MenuItem>
                        <MenuItem as={Link} to="/scenarios"><StyledFontAwesomeIcon icon={faList}/> My scenarios</MenuItem>
                        <MenuItem as={Link} to="/scenarios/history"><StyledFontAwesomeIcon icon={faHistory}/> Scenario history</MenuItem>
                    </DropdownButton>

                    <DropdownButton title="Learn">
                        <MenuItem as={Link} to="/intro" className={currentPath === "/intro" ? "active" : ""}><StyledFontAwesomeIcon icon={faPlay}/> Quick-start intro</MenuItem>
                        <MenuItem as={Link} to="/academy" className={currentPath === "/academy" ? "active" : ""}><StyledFontAwesomeIcon icon={faBuilding}/> OKAI academy</MenuItem>
                        <MenuItem as={Link} to="/faq" className={currentPath === "/faq" ? "active" : ""}><StyledFontAwesomeIcon icon={faQuestion}/> FAQ</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/company-training"><StyledFontAwesomeIcon icon={faBookOpen}/> Company training</MenuItem>
                    </DropdownButton>

                    {isAdmin && (<DropdownButton title="Manage">
                        <MenuItem as={Link} to="/projects" className={currentPath === "/projects" ? "active" : ""}><StyledFontAwesomeIcon icon={faProjectDiagram}/> Manage projects</MenuItem>
                        <MenuItem as={Link} to="/clients" className={currentPath === "/clients" ? "active" : ""}><StyledFontAwesomeIcon icon={faUserAlt}/> Manage clients</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/learning" className={currentPath.startsWith("/learning") ? "active" : ""}><StyledFontAwesomeIcon icon={faBookReader}/> Manage learning</MenuItem>
                    </DropdownButton>)}
                </MiddleAlignedContainer>
            }
            {isAuthenticated && user && (
                <RightAlignedContainer>
                    {
                        selectedClient && <div style={{ width: "60px", height: "auto", margin: "auto" }}><img src={`${process.env.REACT_APP_API_URL}${selectedClient.logo}`} width={75}/></div>
                    }
                    {/* 
                    <IconButton>
                        <FontAwesomeIcon color={theme.colors.primary} size="sm" icon={faCircleQuestion} alt="help"/> }
                    </IconButton>
                    <IconButton>
                        <img src={bellSymbol} alt="Notifications" />
                    </IconButton>
                    */}
                    <DropdownButtonUser>
                        <MenuItem as={Link}  to="/profile" >
                            <UserProfile>
                                <div css={AvatarIconStyle} />
                                <UserInfo>
                                    <UserName>{userName}</UserName>
                                    <UserEmail>{userEmail}</UserEmail>
                                </UserInfo>
                            </UserProfile>
                        </MenuItem>
                        <LineDivider />
                        <MenuItem as={Link} to="/profile" className={currentPath === "/profile" ? "active" : ""}>
                            <StyledFontAwesomeIcon icon={faUser}/> Profile settings
                        </MenuItem>
                        <MenuItem as={Link} >
                            <StyledFontAwesomeIcon  icon={faCircleQuestion}/> Help 
                        </MenuItem>
                        <LineDivider/>
                        {
                            projects && projects.length > 0 && <>
                                <MenuItem>Select project:</MenuItem>
                                <ProjectListContainer>
                                    <RadioProvider>
                                        {
                                            projects.map((project, i) => {
                                                const radioRef = React.createRef();
              
                                                const handleClick = () => {
                                                    radioRef.current.click();
                                                    setSelectedProject(project);
                                                };

                                                return (
                                                    <ProjectListElement 
                                                        key={i}
                                                        onClick={handleClick}
                                                    >
                                                        <StandardRadio
                                                            ref={radioRef}
                                                            size="s"
                                                            group="projects"
                                                            value={project.id}
                                                            initState={project.id === selectedProject?.id}
                                                        />
                                                        <ProjectListTitle>{project.name}</ProjectListTitle>
                                                    </ProjectListElement>
                                                )
                                            })
                                        }
                                    </RadioProvider>
                                </ProjectListContainer>
                            </>
                        }
                        <LineDivider />
                        <MenuItem as={Link} to="/teams" className={currentPath === "/teams" ? "active" : ""}>
                            <StyledFontAwesomeIcon icon={faUsers}/> Manage teams
                        </MenuItem>
                        <LineDivider />
                        <MenuItem as={Link} to="/logout"><StyledFontAwesomeIcon icon={faSignOut}/> Log out</MenuItem>
                    </DropdownButtonUser>
                </RightAlignedContainer>
            )}
            {!isAuthenticated && <MenuItem as={Link} to="/login">Login</MenuItem>}
        </WhiteNavbar>
    );
}
export default Navigation;