/* SearchLogic.js
 * This module provides the search functionality for grid components in our application.
 * It implements a sophisticated ranking system for search results across different data types,
 * supporting partial matches, case sensitivity, and custom value mappings.
 * 
 * The search algorithm:
 * 1. Breaks search input into keywords
 * 2. Scores each data item against all keywords
 * 3. Only returns results where all keywords match somewhere
 * 4. Ranks results by total score
 */

/* Scoring weights for different match types
 * Higher weights indicate more significant matches
 */
const RANKING_SCORES = {
    EXACT_MATCH: 100,        // Complete string match
    WORD_START: 25,         // Word boundary match (e.g., "tech" matches "technology")
    SUBSTRING_MATCH: 15,    // Match anywhere in string
    CASE_MATCH: 10,         // Correct case matching
    CONSECUTIVE_CHAR: 0.3,  // Bonus for each consecutive matching character
    LENGTH_FACTOR: 0.2,     // Bonus multiplier for match length
    EXACT_CASE_CHAR: 0.1,   // Small bonus for individual character case matches
};

/* String matching algorithm with weighted scoring
 * Returns a normalized score based on match quality
 */
const calculateStringMatchScore = (source, searchTerm) => {
    if (!source || !searchTerm) return 0;
    
    const sourceStr = String(source).trim();
    const searchStr = String(searchTerm).trim();
    
    const sourceLower = sourceStr.toLowerCase();
    const searchLower = searchStr.toLowerCase();
    
    // First check if there's any match at all (case-insensitive)
    if (!sourceLower.includes(searchLower)) {
        return 0;
    }
    
    let score = 0;
    
    // 1. Exact string match (highest priority)
    if (sourceStr === searchStr) {
        score += RANKING_SCORES.EXACT_MATCH;
    }
    
    // 2. Find best matching position and calculate consecutive bonuses
    const position = sourceLower.indexOf(searchLower);
    if (position !== -1) {
        // Word boundary detection
        const isWordStart = position === 0 || !(/[a-z0-9]/i.test(sourceStr[position - 1]));
        
        // Position penalty (higher score for matches closer to start)
        const positionPenalty = 1 - (position / sourceStr.length);
        
        // Length ratio (longer matches get higher scores)
        const lengthRatio = searchStr.length / sourceStr.length;
        
        // Add position-based score
        if (isWordStart) {
            score += RANKING_SCORES.WORD_START * positionPenalty;
        }
        
        // Add substring match score with position and length factors
        score += RANKING_SCORES.SUBSTRING_MATCH * positionPenalty * lengthRatio;
        
        // Add consecutive character bonus
        score += searchStr.length * RANKING_SCORES.CONSECUTIVE_CHAR;
        
        // Add exact case matching bonus for each character
        for (let i = 0; i < searchStr.length; i++) {
            if (sourceStr[position + i] === searchStr[i]) {
                score += RANKING_SCORES.EXACT_CASE_CHAR;
            }
        }
        
        // Length bonus for substantial matches
        if (searchStr.length > 2) {
            score += searchStr.length * RANKING_SCORES.LENGTH_FACTOR;
        }
    }
    
    return Math.round(score * 10) / 10;
};

/* Type-specific handlers for different data types
 * Each handler normalizes its type to a searchable format and returns a score
 * 
 * For boolean values, uses valueMapping to convert to searchable strings
 * Example mapping: { true: 'active', false: 'inactive' }
 */
const searchHandlers = {
    string: (value, searchTerm) => calculateStringMatchScore(value, searchTerm),
    number: (value, searchTerm) => {
        const numSearchTerm = Number(searchTerm);
        return !isNaN(numSearchTerm) && value === numSearchTerm ? RANKING_SCORES.EXACT_MATCH : 0;
    },
    datetime: (value, searchTerm) => {
        const dateStr = new Date(value).toLocaleDateString();
        return calculateStringMatchScore(dateStr, searchTerm);
    },
    boolean: (value, searchTerm, header) => {
        if (!header.valueMapping) return 0;
        
        // Get the string representation from the mapping
        const mappedValue = header.valueMapping[value];
        if (!mappedValue) return 0;
        
        // Use the standard string matching on the mapped value
        return calculateStringMatchScore(mappedValue, searchTerm);
    }
};

/* Utility to ensure each data item has a unique identifier
 * Required for stable sorting and result tracking
 */
export const ensureDataIds = (data) => {
    return data.map((item, index) => ({
        ...item,
        _searchId: item.id || item._searchId || `generated_id_${index}`
    }));
};

/* Main search function
 * Handles multi-keyword search across all searchable fields
 * Requires ALL keywords to match somewhere for a result to be included
 * 
 * @param data - Array of data objects to search
 * @param headers - Column definitions including search configuration
 * @param searchTerm - Space-separated search terms
 * @returns Object containing matched IDs and their scores
 */
export const performSearch = (data, headers, searchTerm) => {
    if (!searchTerm) return { 
        results: data.map(item => item._searchId),
        scores: new Map()
    };
    
    const keywords = searchTerm.trim().split(/\s+/);
    const scores = new Map();
    
    // Initialize all scores to 0
    data.forEach(item => scores.set(item._searchId, 0));
    
    // Process each data item
    data.forEach(item => {
        let totalItemScore = 0;
        let keywordMatches = new Array(keywords.length).fill(false);
        
        // Check each keyword
        keywords.forEach((keyword, keywordIndex) => {
            let keywordScore = 0;
            
            // Check each searchable field for this keyword
            headers.forEach(header => {
                if (!header.isSearchable) return;
                
                const value = item[header.key];
                
                // Custom ranking rule (kept for backward compatibility)
                if (header.searchRankingRule) {
                    const customScore = header.searchRankingRule(keyword, value) || 0;
                    keywordScore += customScore;
                    if (customScore > 0) keywordMatches[keywordIndex] = true;
                    return;
                }
                
                // Type-specific handler with header context
                const handler = searchHandlers[header.type];
                if (handler) {
                    const score = handler(value, keyword, header);
                    keywordScore += score;
                    if (score > 0) keywordMatches[keywordIndex] = true;
                }
            });
            
            totalItemScore += keywordScore;
        });
        
        // Only set score if ALL keywords had at least one match
        if (keywordMatches.every(match => match)) {
            scores.set(item._searchId, totalItemScore);
        } else {
            scores.set(item._searchId, 0);
        }
    });
    
    // Sort and return results
    const sortedResults = Array.from(scores.entries())
        .sort((a, b) => b[1] - a[1])
        .filter(([_, score]) => score > 0); // Remove items with zero scores
        
    return {
        results: sortedResults.map(([id]) => id),
        scores: scores
    };
};

export default {
    performSearch,
    ensureDataIds,
};