import React, { useEffect, useState } from "react";
import { Container, IconButton, InputGroup, Input, Panel, Stack, Message } from "rsuite";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import SearchIcon from '@rsuite/icons/Search';
import VisibleIcon from '@rsuite/icons/Visible';
import CollapsedOutlineIcon from '@rsuite/icons/CollaspedOutline';  // Fixed typo in import
import EditIcon from '@rsuite/icons/Edit';

import { useNavigate } from "react-router-dom";
import { TagSuccess, TagDanger, TagWarning, faEyeSlash } from '../ui_reusable/styled_comps/Tags';

import VersatileGrid from '../ui_reusable/VersatileGrid';
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../ui_reusable/VersatileGridUtils';
import { SmallStandardButton, EasyIconButton } from '../ui_reusable/styled_comps/Buttons';
import SectionTitle from "../ui_reusable/SectionTitle";
import CreateDataset from "./Create";
import ShowDataset from "./Show";
import { HeaderContainer } from '../ui_reusable/styled_comps/PlacementMisc';
import { HeaderMedium600Style } from "../styles/TextStyles";
import { StatsContainer } from '../clients/styled_sub_comps/ListStyledSubComps';
import { SmallNormalTag } from '../ui_reusable/styled_comps/Tags';
import SimpleAccordion from "../ui_reusable/SimpleAccordion";

import CustomIcons from '../ui_reusable/CustomIcons';

const DatasetList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [datasets, setDatasets] = useState([]);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [showCreateDataset, setShowCreateDataset] = useState(false);
    const [expandedRows, setExpandedRows] = useState(new Set());

    // Fetch datasets
    useEffect(() => {
        const getDatasets = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/datasets/?limit=99999`,
                    { 
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setDatasets(res.data);
            } catch (error) {
                console.error('Failed to fetch datasets:', error);
            } finally {
                setLoading(false);
            }
        };
        getDatasets();
    }, [refresh]);

    const handleManage = (id) => {
        navigate('/datasets/'+id);
    };

    const handleExpand = (dataIdx) => {
        setExpandedRows(prev => {
            const next = new Set(prev);
            if (next.has(dataIdx)) {
                next.delete(dataIdx);
            } else {
                next.add(dataIdx);
            }
            return next;
        });
    };

    // Grid headers configuration
    const headers = [
        {
            title: 'Dataset name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            flexGrow: 2
        },
        {
            title: 'Source',
            key: 'source',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Interval',
            key: 'interval',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last updated',
            key: 'created_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Observations',
            key: 'observations',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Units',
            key: 'units',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Quality',
            key: 'quality',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View/Edit',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    // Grid header stats
    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Total Datasets: {datasets.length}</span>
            <div>
                <SmallNormalTag>
                    {datasets.filter(d => d.quality === 'good').length} good
                </SmallNormalTag>
                <SmallNormalTag>
                    {datasets.filter(d => d.quality === 'check').length} check
                </SmallNormalTag>
                <SmallNormalTag>
                    {datasets.filter(d => d.quality === 'poor').length} poor
                </SmallNormalTag>
            </div>
        </StatsContainer>
    );

    // Modified render intersecting row content to use CSS visibility
    const renderIntersectingRow = (rowData, rowIdx, dataIdx) => {
        return (
            <div style={{ 
                display: expandedRows.has(dataIdx) ? 'block' : 'none',
                height: expandedRows.has(dataIdx) ? 'auto' : 0,
                overflow: 'hidden',
                transition: 'height 0.3s ease-in-out',
            }}>
                <ShowDataset datasetId={rowData.id} units={rowData.units} />
            </div>
        );
    };

    return (
        <>
            {/* Page Header Section */}
            <HeaderContainer>
                <SectionTitle>Manage Datasets</SectionTitle>
            </HeaderContainer>

            {/* Main Content Section */}
            <SimpleAccordion defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Dataset List"
                    eventKey="1"
                    noCollapse
                >
                    {/* Dataset Creation Interface */}
                    <CreateDataset 
                        setRefresh={setRefresh} 
                        setShowCreateDataset={setShowCreateDataset}
                    />

                    {/* Dataset Data Grid */}
                    <VersatileGrid
                        headers={headers}
                        data={datasets}
                        searchPlaceholder="Find dataset"
                        renderRow={(rowData, rowIdx, dataIdx) => [
                            <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                            <GridCellNormalText>{rowData.source}</GridCellNormalText>,
                            <GridCellNormalText>{rowData.interval}</GridCellNormalText>,
                            <GridCellSmallFadedText>
                                {`${new Date(rowData.created_at).toLocaleDateString()}@${new Date(rowData.created_at).getHours()}:${new Date(rowData.created_at).getMinutes()}`}
                            </GridCellSmallFadedText>,
                            <GridCellNormalText>{rowData.observations}</GridCellNormalText>,
                            <GridCellNormalText>{rowData.units}</GridCellNormalText>,
                            <GridCellNormalText>
                                {rowData.quality === "good" && <TagSuccess label={rowData.quality} />}
                                {rowData.quality === "check" && <TagWarning label={rowData.quality} />}
                                {rowData.quality === "poor" && <TagDanger label="Fix" />}
                            </GridCellNormalText>,
                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                                <EasyIconButton onClick={() => handleExpand(dataIdx)}>
                                    {expandedRows.has(dataIdx) ? 
                                        <CustomIcons.faEyeSlash  solid={false} size="small" /> : 
                                        <CustomIcons.faEye  solid={false} size="small" />
                                    }
                                </EasyIconButton>
                                <EasyIconButton onClick={() => handleManage(rowData.id)}>
                                    <CustomIcons.PenIcon size="small" />
                                </EasyIconButton>
                            </div>
                        ]}
                        renderIntersectingRow={renderIntersectingRow}
                        headerInfo={gridHeader}
                        maxDisplayedItems={10}
                        loading={loading}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>
        </>
    );
};

export default DatasetList;