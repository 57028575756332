import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { IconButton, Message, Uploader } from "rsuite";
import CloseOutline from '@rsuite/icons/CloseOutline';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import useStandardToaster from '../ui_reusable/StandardToaster';
import FileValidationModal from "./FileValidationModal";
import { FormSelectPicker } from "../ui_reusable/StandardSelectPickers";
import { aggregationMethodOptions, influenceOptions, intervals, investmentOptions, units_list } from "../../utils/dropdowns";
import CreatorAccordion from "../ui_reusable/CreatorAccordion";
import { Container, FormGroup, Label, ButtonContainer, ErrorMessage } from '../ui_reusable/styled_comps/CreateInputUtils';
import { VStack, HStack ,VStackBroad} from './styled_sub_comps/CreateStyledSubComps';
import { StandardButton } from '../ui_reusable/styled_comps/Buttons';
import { StandardInput } from '../ui_reusable/StandardInputs';
import FileUploader from '../ui_reusable/FileUploader';

const CreateDataset = ({ setRefresh, setShowCreateDataset }) => {
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState();
    const [open, setOpen] = useState(false);
    const [read, setRead] = useState(false);
    const { pushToast } = useStandardToaster();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            file: "",
            name: "",
            source: "",
            description: "",
            interval: "monthly",
            aggregation: "mean",
            units: "",
            influence: "",
            investment: ""
        }
    });

    const handleOpen = () => setOpen(true);
    



    const handleClose = () => {
        setOpen(false);
        setRead(true);
    };

    const handleFileChange = (files) => {
        if (!files || !files[0]) return;
        setUploading(true);
        setFileInfo(files[0]);
        setUploading(false);
    };

    const handleRemoveFile = () => {
        setFileInfo(null);
    };

    const onSubmit = async (formData) => {
        if (formData.interval && formData.units) {
            const payload = new FormData();
            Object.keys(formData).forEach(key => {
                payload.append(key, formData[key]);
            });
            if (fileInfo) {
                payload.append('file', fileInfo.blobFile);
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/`, { 
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                method: 'POST',
                body: payload
            });
            if (response.status === 201) {
                setFileInfo(null);
                pushToast(<Message type="success">Dataset created successfully.</Message>, { placement: 'topCenter', duration: 5000 });
                setShowCreateDataset(false);
                setRefresh(new Date().getTime());
            } else if (response.status === 400) {
                const error = await response.json();
                pushToast(<Message type="error">{error.message}</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                pushToast(<Message type="error">Failed to create dataset.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        } else {
            pushToast(<Message type="error">Select both interval and units.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    const acceptedFileTypes = ".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    return (
        <CreatorAccordion>
            <CreatorAccordion.Panel 
                header="Add new dataset"
                initClosed={true}
            >
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <VStackBroad>
                                <HStack>
                                    <VStack>
                                        <Label>File</Label>
                                        <div >
                                           
                                            <FileUploader
                                                fileInfo={fileInfo}
                                                onFileSelect={handleFileChange}
                                                onRemove={handleRemoveFile}
                                                uploading={uploading}
                                                acceptedTypes={acceptedFileTypes}

                                            />
                                            <StandardButton type="button" onClick={handleOpen}>
                                                Select file
                                            </StandardButton>
                                    
                                        
                                            <IconButton 
                                                type="button" 
                                                onClick={handleOpen} 
                                                icon={<InfoOutlineIcon />} 
                                            />
                                            
                                        </div>
                                    </VStack>
                                </HStack>
                                <HStack>
                                    <VStack>
                                        <Label>Dataset name</Label>
                                        <StandardInput 
                                            {...register("name", { required: "Dataset name is required" })} 
                                        />
                                        <ErrorMessage>{errors.name?.message}</ErrorMessage>
                                    </VStack>
                                    <VStack>
                                        <Label>Source</Label>
                                        <StandardInput 
                                            {...register("source", { required: "Source is required" })} 
                                            style={{ width: "150px" }}
                                        />
                                        <ErrorMessage>{errors.source?.message}</ErrorMessage>
                                    </VStack>
                                    <VStack>
                                        <Label>Description</Label>
                                        <StandardInput 
                                            {...register("description")} 
                                        />
                                        <ErrorMessage>{errors.description?.message}</ErrorMessage>
                                    </VStack>
                                </HStack>
                                <HStack>
                                    <VStack>
                                        <Label>Interval</Label>
                                        <FormSelectPicker 
                                            data={intervals} 
                                            value={watch("interval")} 
                                            onChange={value => setValue("interval", value)} 

                                        />
                                        <ErrorMessage>{errors.interval?.message}</ErrorMessage>
                                    </VStack>
                                    {watch("interval") && watch("interval") !== 'monthly' && (
                                        <VStack>
                                            <Label>Aggregation methods</Label>
                                            <FormSelectPicker 
                                                data={aggregationMethodOptions} 
                                                value={watch("aggregation")} 
                                                onChange={value => setValue("aggregation", value)} 

                                            />
                                            <ErrorMessage>{errors.aggregation?.message}</ErrorMessage>
                                        </VStack>
                                    )}
                                    <VStack>
                                        <Label>Units</Label>
                                        <FormSelectPicker 
                                            data={units_list} 
                                            value={watch("units")} 
                                            onChange={value => setValue("units", value)} 

                                        />
                                        <ErrorMessage>{errors.units?.message}</ErrorMessage>
                                    </VStack>
                                    <VStack>
                                        <Label>Influence</Label>
                                        <FormSelectPicker 
                                            data={influenceOptions} 
                                            value={watch("influence")} 
                                            onChange={value => setValue("influence", value)} 

                                        />
                                        <ErrorMessage>{errors.influence?.message}</ErrorMessage>
                                    </VStack>
                                    <VStack>
                                        <Label>Investment</Label>
                                        <FormSelectPicker 
                                            data={investmentOptions} 
                                            value={watch("investment")} 
                                            onChange={value => setValue("investment", value)} 

                                        />
                                        <ErrorMessage>{errors.investment?.message}</ErrorMessage>
                                    </VStack>
                                </HStack>
                            </VStackBroad>
                            <ButtonContainer>
                                <StandardButton type="submit">SAVE DATASET</StandardButton>
                            </ButtonContainer>
                        </FormGroup>
                    </form>
                    {open && <FileValidationModal open={open} handleClose={handleClose} setRead={setRead} />}
                </Container>
            </CreatorAccordion.Panel>
        </CreatorAccordion>
    );
};

export default CreateDataset;