import { css, Global } from '@emotion/react/macro';

export const DocumentScreenBehaviour = css`
  width: 80rem;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
`;

export const DocumentBelowNavbarBehaviour = css`
  padding-left: 1.8rem;
  padding-right:1.8rem;
`;

