import { useState, useRef } from "react";
import { Message, SelectPicker } from "rsuite";
import { useForm } from "react-hook-form";
import { StandardButton } from "../../ui_reusable/styled_comps/Buttons";
import { StandardInput } from "../../ui_reusable/StandardInputs";
import useStandardToaster from '../../ui_reusable/StandardToaster';
import { ButtonContainer, Label, FormGroup, FormSection, GridLayout, Container, ErrorMessage, SuccessContainer } from '../../ui_reusable/styled_comps/CreateInputUtils';
import { roles_list } from "../../../utils/dropdowns";
import CreatorAccordion from "../../ui_reusable/CreatorAccordion";
import CheckmarkAnimation from '../../ui_reusable/CheckmarkAnimation';

const CreateUser = ({ clientId, setRefresh }) => {
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState("user");
    const { pushToast } = useStandardToaster();
    const panelRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [removeContainer, setRemoveContainer] = useState(true);  // Add this state

    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors },
        clearErrors,
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    // Handle input changes to clear errors when user starts typing
    const handleInputChange = (fieldName) => {
        if (errors[fieldName]) {
            clearErrors(fieldName);
        }
    };

    // Form submission handler
    const onSubmit = async (formData) => {
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${clientId}/users/`, { 
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    user: {
                        ...formData,
                    },
                    role
                })
            });

            const responseData = await response.json();

            if (response.status === 201) {
                setRemoveContainer(false);  // Add container to DOM
                setShowSuccess(true);       // Start animation
                
                await setRefresh(Date.now());
                reset();
                setRole("user");

                setTimeout(() => {
                    if (panelRef.current) {
                        panelRef.current.tryCollapse();
                        setShowSuccess(false); // Hide animation after collapse
                        // Remove container after animation completes
                        setTimeout(() => setRemoveContainer(true), 300);
                    }
                }, 1500);
            } else {
                throw new Error(responseData.message || 'Failed to create user');
            }
        } catch (error) {
            pushToast(
                <Message type="error">{error.message}</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            console.error('Failed to create user:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CreatorAccordion>
            <CreatorAccordion.Panel 
                ref={panelRef}
                header="Add new user" 
                initClosed={true}
            >
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GridLayout>
                            <FormSection>
                                <FormGroup>
                                    <Label htmlFor="name">Name</Label>
                                    <StandardInput
                                        id="name"
                                        {...register("name", { 
                                            required: "Name is required",
                                            onChange: () => handleInputChange("name")
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors.name?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="email">Email</Label>
                                    <StandardInput
                                        id="email"
                                        type="email"
                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                message: "Please enter a valid email address"
                                            },
                                            onChange: () => handleInputChange("email")
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors.email?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>
                            </FormSection>
                            <FormSection>
                                <FormGroup>
                                    <Label htmlFor="phone">Phone</Label>
                                    <StandardInput
                                        id="phone"
                                        {...register("phone", {
                                            required: "Phone number is required",
                                            pattern: {
                                                value: /^[\d\s+()-]{8,}$/,
                                                message: "Please enter a valid phone number (minimum 8 digits)"
                                            },
                                            onChange: () => handleInputChange("phone")
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors.phone?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="role">Role</Label>
                                    <SelectPicker 
                                        searchable={false} 
                                        cleanable={false} 
                                        style={{minWidth: 300}} 
                                        data={roles_list} 
                                        value={role} 
                                        onChange={value => setRole(value)}
                                    />
                                </FormGroup>
                            </FormSection>
                        </GridLayout>
                        <ButtonContainer>
                            <StandardButton type="submit">
                                {loading ? 'Saving...' : 'Save user'}
                            </StandardButton>
                        </ButtonContainer>
                    </form>
                    {!removeContainer && (
                        <SuccessContainer>
                            <CheckmarkAnimation 
                                show={showSuccess} 
                                text="User created successfully!"
                            />
                        </SuccessContainer>
                    )}
                </Container>
            </CreatorAccordion.Panel>
        </CreatorAccordion>
    );
}

export default CreateUser;
