import React, { useState, useCallback } from 'react';
import { Uploader } from 'rsuite';
import CustomIcons from './CustomIcons';
import theme from '../../styles/theme';
import {
    FilePanel,
    DragZone,
    FileInfo,
    FileName,
    UploadText,
    RemoveButton,
    FileContainer
} from './styled_sub_comps/FileUploaderStyledComps';

const FileUploader = ({ 
    onFileSelect,
    onRemove,
    fileInfo,
    uploading,
    acceptedTypes
}) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleFileChange = useCallback((files) => {
        if (!files || files.length === 0) return;
        onFileSelect(files);
    }, [onFileSelect]);

    // Reset the uploader's internal state when file is removed
    const handleReset = (e) => {
        e.stopPropagation();
        if (onRemove) {
            onRemove();
        }
        // Force reset the uploader's internal state
        if (e.currentTarget.closest('.rs-uploader')) {
            const uploader = e.currentTarget.closest('.rs-uploader');
            const input = uploader.querySelector('input[type="file"]');
            if (input) {
                input.value = '';
            }
        }
    };

    return (
        <FilePanel>
            <Uploader
                action=''
                autoUpload={false}
                fileListVisible={false}
                accept={acceptedTypes}
                draggable
                multiple={false}
                onChange={handleFileChange}
                disabled={uploading}
                onDragEnter={() => setIsDragging(true)}
                onDragLeave={() => setIsDragging(false)}
                onDrop={() => setIsDragging(false)}
                key={fileInfo ? 'has-file' : 'no-file'} // Force remount when file state changes
            >
                <DragZone isDragging={isDragging} hasFile={Boolean(fileInfo)}>
                    {fileInfo ? (
                        <FileContainer>
                            <FileInfo>
                                <CustomIcons.faFile 
                                    size="small" 
                                    color={theme.colors.backgroundDark}
                                />
                                <FileName>{fileInfo.name}</FileName>
                            </FileInfo>
                            <RemoveButton
                                onClick={handleReset}
                                type="button"
                                title="Remove file"
                            >
                                <CustomIcons.faTimes 
                                    size="small"
                                    color={theme.colors.backgroundVeryDark}
                                    hoverColor={theme.colors.error}
                                />
                            </RemoveButton>
                        </FileContainer>
                    ) : (
                        <UploadText>Click or drag a file to upload</UploadText>
                    )}
                </DragZone>
            </Uploader>
        </FilePanel>
    );
};

export default FileUploader;
