import styled from '@emotion/styled';
import { TextLarge500Style, InfoMediumMaxi500Style,TextMediumMaxi600Style} from '../../../styles/TextStyles';
import { InputWrapper } from '../../../styled_sub_comps/ProfileStyledSubComps';
import { css } from '@emotion/react';
import { DataValueLabel, DataVariableLabel } from '../../../ui_reusable/styles/DataInfoUtils';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  padding: 1rem;

`;

export const LogoSection = styled.div`
    width: 40%;
`;

export const EditableFieldContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
`;

export const InfoSection = styled.div`
  width: 60%;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NormalCell = styled(Cell)`
  height: 2rem;
  display: flex;
  align-items: center;  
`;

export const ShrankCell = styled(Cell)`
  height: 1.5rem;
  display: flex;
  align-items: top;  
`;

//minmax(auto, max-content)
export const ProjectSettingsGrid = styled.div`
    ${DataValueLabel}
    margin-top: 1rem;
    display: grid;
    grid-template-columns:  minmax(auto, max-content) minmax(auto, max-content) 1fr minmax(auto, max-content) minmax(auto, max-content);
    grid-template-rows: repeat(5, minmax(auto, max-content));
    align-items: stretch; /* Ensures all items stretch to fill the grid cell height */
    width: 100%;
    margin-right: 1rem;


    //select the first column 
    & >  :nth-child(5n+1) {
      ${DataVariableLabel}
      justify-self: start;
      padding-right: 1rem;
    }

    //select the forth column 
    & >  :nth-child(5n+4) {
      ${DataVariableLabel}
      justify-self: start;
      padding-right: 1rem;
    }

    //select the fifth row
    & >  :nth-child(n+26){
      margin-top: 3rem;
    }
`