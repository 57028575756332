import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GearIcon from '@rsuite/icons/Gear'; // Add GearIcon import

import SectionTitle from "../ui_reusable/SectionTitle";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import VersatileGrid from '../ui_reusable/VersatileGrid';
import { TagSuccess, TagWarning } from '../../utils/tag'; // Remove TagDefault
import CreateProject from "./Create";
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../ui_reusable/VersatileGridUtils';
import { VerySmallNormalTag, SmallNormalTag } from "../ui_reusable/styled_comps/Tags"; // Add SmallNormalTag
import { SmallStandardButton } from '../ui_reusable/styled_comps/Buttons';
import {ThickSelectPicker} from '../ui_reusable/StandardSelectPickers';
import { HeaderContainer } from '../ui_reusable/styled_comps/PlacementMisc';
import { StatsContainer } from '../clients/styled_sub_comps/ListStyledSubComps';
import { HeaderMedium600Style } from "../styles/TextStyles";
import Footer from "../Footer";
import { useProjectContext } from "../../context/ProjectContext";
import { useClientContext } from "../../context/ClientContext";

const ProjectList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());

    const {selectedClient, setSelectedClient} = useClientContext();
    const {selectedProject, setSelectedProject} = useProjectContext();
    
    // Fetch all projects
    useEffect(() => {
        const getProjects = async (url) => {
            setLoading(true);
            try {
                const response = await fetch(
                    url,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setProjects(res.data || []);
            } catch (error) {
                console.error('Failed to fetch projects:', error);
                setProjects([]);
            } finally {
                setLoading(false);
            }
        };
        if(selectedClient){
            getProjects(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}`);
        }
    }, [refresh]);

    const handleManage = (projectId) => {
        if (!projectId) return;
        const project = projects.find(p => p.id === projectId);
        setSelectedProject({
            id: project.id,
            name: project.name,
            logo: project.logo,
        });
        navigate(`/projects/${projectId}`, {
            state: {
                id: projectId,
                projects: projects?.map(p => ({ id: p.id, name: p.name, logo: p.logo })) || []
            }
        });
    };

    // Grid configuration
    const headers = [
        {
            title: 'Project name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Client',
            key: 'client',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Created',
            key: 'created_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true,
            center:true
        },
        {
            title: 'Solutions',
            key: 'solutions',
            type: 'string',
            isSortable: false,
            isSearchable: true,
            center: true,
            getValue: (rowData) => rowData.solutions.join(' ')
        },
        {
            title: 'Status',
            key: 'status',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'KAM',
            key: 'kam',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: '',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false
        }
    ];

    // Statistics for the header
    const stats = {
        total: projects.length,
        active: projects.filter(p => p.status === 'active').length,
        inactive: projects.filter(p => p.status !== 'active').length
    };

    // Grid header component with stats - Updated to use SmallNormalTag instead of TagDefault
    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Total Projects: {stats.total}</span>
            <div>
                <SmallNormalTag>{stats.active} active</SmallNormalTag>
                <SmallNormalTag>{stats.inactive} inactive</SmallNormalTag>
            </div>
        </StatsContainer>
    );

    return (
        <>
            {/* Page Header Section */}
            <HeaderContainer>
                <SectionTitle>Manage Projects</SectionTitle>
                <ThickSelectPicker 
                    placeholder="Select project"
                    data={projects.map(project => ({
                        label: project.name,
                        value: project.id
                    }))}
                    value={selectedProject?.id}
                    onChange={handleManage}
                    style={{ width: 250 }}
                />
            </HeaderContainer>

            {/* Main Content Section */}
            <SimpleAccordion defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Project List"
                    eventKey="1"
                    noCollapse
                >
                    {/* Project Creation Interface */}
                    <CreateProject 
                        setRefresh={setRefresh} 
                        setShowCreateProject={setShowCreateProject}
                    />
                    
                    {/* Project Data Grid */}
                    <VersatileGrid 
                        headers={headers}
                        data={projects}
                        renderRow={(rowData) => [
                            <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                            <GridCellNormalText>{rowData.client}</GridCellNormalText>,
                            <GridCellSmallFadedText>
                                {new Date(rowData.created_at).toLocaleDateString()}
                            </GridCellSmallFadedText>,
                            <GridCellNormalText>
                                {rowData.solutions.length > 0 ? (
                                    rowData.solutions.map((solution, index) => (
                                        <VerySmallNormalTag key={index}>{solution}</VerySmallNormalTag>
                                    ))
                                ) : (
                                    <GridCellSmallFadedText>No solutions</GridCellSmallFadedText>
                                )}
                            </GridCellNormalText>,
                            <GridCellNormalText>
                                {rowData.status === 'active' ? (
                                    <TagSuccess label={rowData.status} />
                                ) : (
                                    <TagWarning label={rowData.status} />
                                )}
                            </GridCellNormalText>,
                            <GridCellSmallFadedText>{rowData.kam || 'N/A'}</GridCellSmallFadedText>,
                            <SmallStandardButton
                                onClick={() => handleManage(rowData.id)}
                                startIcon={<GearIcon />}
                            >
                                Manage
                            </SmallStandardButton>
                        ]}
                        maxDisplayedItems={8}
                        loading={loading}
                        headerInfo={gridHeader}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>
            <Footer />
        </>
    );
};

export default ProjectList;