import React, { useState, useEffect, memo } from 'react';
import { standardCheckboxStyle, standardInputStyle } from './styles/StandardInputStyles';

// Standard Checkbox Component with controlled state
export const StandardCheckbox = ({ onChange, initState = false, disabled = false }) => {
  const [isChecked, setIsChecked] = useState(initState);

  useEffect(() => {
    setIsChecked(initState);
  }, [initState]);

  const handleChange = (e) => {
    if (disabled) return;
    const newState = e.target.checked;
    setIsChecked(newState);
    if (onChange) onChange(newState);
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={handleChange}
      disabled={disabled}
      css={[
        standardCheckboxStyle,
        disabled && {
          cursor: 'not-allowed',
          opacity: 0.5,
        }
      ]}
    />
  );
};

// Standard Input Component with consistent styling across the application
export const StandardInput = memo(React.forwardRef(({ 
    onChange, 
    value, 
    type = "text",
    disabled = false,
    ...props 
}, ref) => {
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };

    return (
        <input
            ref={ref}
            type={type}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            css={standardInputStyle}
            {...props}
        />
    );
}));

