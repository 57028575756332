/**
 * This is ReportAccordion which inheris from BaseAccordion
 * The main differences between all inherited accordions is applied styling and the header rendering.
 * This one is used in the report page for model runs.
 * It has
 * - Checkbox for section inclusion/exclusion
 * - Help tooltip with section-specific information
 * - Expand/collapse controls with RSuite icons
 */

import React, { useState } from 'react';
import { Button, Checkbox, Popover, Whisper } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import BaseAccordion from './BaseAccordion';
import { HeaderLarge500Style } from '../styles/TextStyles';
import { AccordionContainer, PanelContainer, PanelHeader, CustomHStack } from './styled_sub_comps/ReportAccordionStyledSubComps';
import { PopoverContent } from './styles/ReportAccordionStyles';

const ReportAccordion = ({ children, ...props }) => (
  <AccordionContainer>
    <BaseAccordion {...props}>
      {children}
    </BaseAccordion>
  </AccordionContainer>
);

/**
 * Panel subcomponent for ReportAccordion
 * @param {string} header - Section title
 * @param {string} helpText - Optional help text shown in popover
 * @param {React.ReactNode} children - Panel content
 */
const Panel = ({ header, helpText, children, ...props }) => {
  // State for section inclusion checkbox
  const [isChecked, setIsChecked] = useState(true);

  // Help popover configuration
  const helpPopover = (
    <Popover>
      <div css={PopoverContent}>
        <div css={HeaderLarge500Style}>Help - {header}</div>
        <div>{helpText || `This section contains important information about ${header}.`}</div>
      </div>
    </Popover>
  );

  return (
    <PanelContainer>
      <BaseAccordion.Panel
        {...props}
        renderHeader={({ isActive, onToggle, noCollapse }) => (
          <PanelHeader>
            <div css={HeaderLarge500Style} onClick={onToggle}>{header}</div>
            <CustomHStack>
              <Checkbox
                checked={isChecked}
                onChange={(_, checked) => setIsChecked(checked)}
              />
              <Whisper
                trigger="click"
                speaker={helpPopover}
                placement="bottomEnd"
                preventOverflow
              >
                <Button appearance="subtle" size="sm">
                  <HelpOutlineIcon />
                </Button>
              </Whisper>
              {!noCollapse && (
                <Button appearance="subtle" size="sm" onClick={onToggle}>
                  {isActive ? <ExpandOutlineIcon /> : <CollaspedOutlineIcon />}
                </Button>
              )}
            </CustomHStack>
          </PanelHeader>
        )}
      >
        {children}
      </BaseAccordion.Panel>
    </PanelContainer>
  );
};

// ...existing exports...

ReportAccordion.Panel = Panel;
export default ReportAccordion;