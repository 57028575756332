import React, { createContext, useContext, useState, useEffect } from "react";

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const storedClient = localStorage.getItem("client");
    if (storedClient) {
      setSelectedClient(JSON.parse(storedClient));
    }
  }, []);

  useEffect(() => {
    if (selectedClient) {
      localStorage.setItem("client", JSON.stringify(selectedClient)); 
    }
  }, [selectedClient]);

  return (
    <ClientContext.Provider value={{ selectedClient, setSelectedClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  return useContext(ClientContext);
};
