import styled from '@emotion/styled';
import { 
    HeaderLarge500Style, 
    TextLarge600Style, 
    HeaderMedium500Style,
    TextMediumMini400Style 
} from '../../styles/TextStyles';

export const WarningSection = styled.div`
  background-color: #feefc7;
  padding: 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  width: 66%;
  min-width: 10rem;
`;


export const OptionsSection = styled.div`
  padding: 2rem;
`;

export const TwoColumnMainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  max-width: 800px;  // Add max-width for better two-column layout
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
`;

export const ControlColumn = styled.div`
  flex: 0 0 auto;
`;

export const ContentColumn = styled.div`
  flex: 1;
`;



