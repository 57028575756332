import React, { useState, useCallback } from 'react';
import { Uploader } from 'rsuite';
import { faImage } from "@fortawesome/free-solid-svg-icons";
import {SecondarySmallButton} from "./styled_comps/Buttons";
import {
    ImagePanel,
    ImagePanelHeader,
    DragZone,
    PreviewImage,
    UploadInstructions,
    UploadIcon,
    UploadText
} from './styled_sub_comps/ImageUploaderStyledComps';

const ImageUploader = ({ 
    onFileSelect,
    onRemove,
    previewUrl,
    fileList,
    uploading,
    headerText = "Image",
    showRemoveButton = true, // New prop to control remove button visibility
    imageHeight = "200px" // Add new prop with default value
}) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleFileChange = useCallback((files) => {
        if (!files || files.length === 0) return;
        onFileSelect(files);
    }, [onFileSelect]);

    return (
        <ImagePanel>
            <ImagePanelHeader>{headerText}</ImagePanelHeader>
            <Uploader
                action=''
                autoUpload={false}
                fileListVisible={true}
                accept="image/*"
                draggable
                multiple={false}
                fileList={fileList}
                onChange={handleFileChange}
                onRemove={onRemove}
                disabled={uploading}
                listType="picture-text" // This will show the file list in a nice format
                defaultFileList={fileList} // Show existing files
                cleanable={showRemoveButton && Boolean(previewUrl)} // Only show remove button if we have a preview and showRemoveButton is true
                onDragEnter={() => setIsDragging(true)}
                onDragLeave={() => setIsDragging(false)}
                onDrop={() => setIsDragging(false)}
                onError={(reason) => {
                    // Suppress upload errors since we're handling files manually
                    console.debug('Upload error suppressed:', reason);
                }}
            >
                <DragZone isDragging={isDragging} imageHeight={imageHeight}> {/* Pass the prop */}
                    {previewUrl ? (
                        <PreviewImage 
                            src={previewUrl} 
                            alt="preview"
                            imageHeight={imageHeight}
                        />
                    ) : (
                        <UploadInstructions>
                            <UploadIcon icon={faImage} />
                            <UploadText>Click or drag a file to upload</UploadText>
                            <SecondarySmallButton size="sm">
                                Select File
                            </SecondarySmallButton>
                        </UploadInstructions>
                    )}
                </DragZone>
            </Uploader>
        </ImagePanel>
    );
};

export default ImageUploader;
