import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Import custom components
import SimpleAccordion from "../../ui_reusable/SimpleAccordion";
import SectionTitle from "../../ui_reusable/SectionTitle";
import {TagFilledActive,TagFilledInactive} from "../../ui_reusable/styled_comps/Tags";
import {ThickSelectPicker}  from "../../ui_reusable/StandardSelectPickers";
import {HeaderContainer} from '../../ui_reusable/styled_comps/PlacementMisc';
// Import existing components
import ClientSettings from "./Settings";
import DeactivateClient from "./Deactivate";
import Users from "./Users";
import ClientProjects from "./Projects";
import CreateUser from "./CreateUser";
import { useClientContext } from "../../../context/ClientContext";

const ConfigClient = () => {
    // Preserve existing logic
    const navigate = useNavigate();
    const location = useLocation();
    const {id, clients} = location.state || {};
    const [client, setClient] = useState(null);
    const [refresh, setRefresh] = useState(new Date().getTime());
    
    const {setSelectedClient} = useClientContext();

    // Fetch client data
    useEffect(() => {
        const getClient = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${id}`,
                { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            const res = await response.json();
            setClient(res.data);
        }
        getClient();
    }, [id, refresh]);

    const handleManage = (id) => {
        const client = clients.find(client => client.id === id);
        setSelectedClient({id:client.id, name:client.name, logo:client.logo});
        navigate('/clients/'+id, {
            state: {
                id, 
                clients: clients.map(e => ({
                    id: e.id, 
                    name: e.name,
                    logo: e.logo
                }))
            }
        });
    };

    if (!client) return null;

    return (
        <>
            {/* Header section with title, status and client picker */}
            <HeaderContainer>
                <SectionTitle>Manage Clients</SectionTitle>
                    
                    {client.is_active ? <TagFilledActive label="Client is active" / > : <TagFilledInactive label="Client is inactive" / >}
                    
                    <ThickSelectPicker 
                        placeholder="Select client"
                        value={client.id}  // Changed from defaultValue to value
                        size="lg" 
                        data={clients.map((client, i) => ({
                            key: i+1,
                            label: client.name,
                            value: client.id
                        }))}
                        onChange={handleManage}
                        style={{ width: 250 }}
                    />

            </HeaderContainer>

            {/* Main content sections using SimpleAccordion */}
            <SimpleAccordion defaultActiveKey={['settings']}>
                <SimpleAccordion.Panel
                    header={'Client settings'}
                    eventKey="settings"
                >
                    <ClientSettings client={client} />
                </SimpleAccordion.Panel>

                <SimpleAccordion.Panel
                    header={'Users'}
                    eventKey="users"
                    initClosed={true}
                >
                    <CreateUser 
                        clientId={id}
                        setRefresh={setRefresh} 
                    />
                    <Users refresh={refresh} setRefresh={setRefresh}/>
                </SimpleAccordion.Panel>

                <SimpleAccordion.Panel
                    header={'Projects'}
                    eventKey="projects"
                    initClosed={true}
                >
                    <ClientProjects />
                </SimpleAccordion.Panel>
{/*
                <SimpleAccordion.Panel
                    header={'Change log'}
                    eventKey="changelog"
                    initClosed={true}
                >
                    
                </SimpleAccordion.Panel>
*/}

                <SimpleAccordion.Panel 
                    header={'Deactivate and delete'}
                    eventKey="deactivate"
                    initClosed={true}
                    color="#fffcf5" 
                    >
                    <DeactivateClient />
                </SimpleAccordion.Panel>
            </SimpleAccordion>
        </>
    );
}

export default ConfigClient;