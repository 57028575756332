import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import CreateClient from "./Create";

import SectionTitle from "../ui_reusable/SectionTitle";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import VersatileGrid from '../ui_reusable/VersatileGrid';
import {SmallStandardButton} from '../ui_reusable/styled_comps/Buttons';
import {GridCellBoldText,GridCellSmallFadedText, ExpansiveListInVersatileGrid, ListItemInVersatileGrid} from "../ui_reusable/VersatileGridUtils";
import { StatsContainer, ProjectTag } from './styled_sub_comps/ListStyledSubComps';
import {SmallNormalTag,TagSuccess,TagWarning} from '../ui_reusable/styled_comps/Tags';
import Footer from "../Footer";
import {ThickSelectPicker} from '../ui_reusable/StandardSelectPickers';
import {HeaderContainer} from '../ui_reusable/styled_comps/PlacementMisc';
/* Layout components for header organization */
import {HeaderMedium600Style} from "../styles/TextStyles";
import { useClientContext } from "../../context/ClientContext";


const ClientList = () => {
    const navigate = useNavigate();
    
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());

    const {selectedClient, setSelectedClient} = useClientContext();
    
    useEffect(() => {
        const getAllClients = async () => {
            setLoading(true);
            try {
   
                let allClients = [];
                let currentPage = 1;
                let hasMorePages = true;

                while (hasMorePages) {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/clients/?page=${currentPage}`,
                        {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }
                    );
                    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const res = await response.json();


                    if (res.data && res.data.length > 0) {
                        allClients = [...allClients, ...res.data];
                        
                        // Check if we've reached the last page
                        hasMorePages = currentPage < res.last_page;
                        currentPage++;
                    } else {
                        hasMorePages = false;
                    }
                }

                console.log("allClients",allClients);
                setClients(allClients);
            } catch (error) {
                console.error("Failed to fetch clients:", error);
                setClients([]);
            } finally {
                setLoading(false);
            }
        };

        getAllClients();
    }, [refresh]); // Remove activePage if not being used


    const handleManage = (id) => {
        const client = clients.find(client => client.id === id);
        setSelectedClient({id:client.id, name:client.name, logo:client.logo});
        navigate('/clients/'+id, {
            state: {
                id, 
                clients: clients.map(e => ({
                    id: e.id, 
                    name: e.name,
                    logo: e.logo
                }))
            }
        });
    };


    const clientStats = {
        total: clients.length,
        active: clients.filter(client => client.is_active).length,
        inactive: clients.filter(client => !client.is_active).length
    };

   
    /* Grid configuration
     * Defines searchable and sortable columns
     * Uses valueMapping for boolean status to enable text search
     */
    const headers = [
        { 
            title: 'Client name', 
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        { 
            title: 'Contact', 
            key: 'contact',
            type: 'string',
            isSortable: true ,
            isSearchable: true
        },
        { 
            title: 'KAM', 
            key: 'kam',
            type: 'string',
            isSortable: true ,
            isSearchable: true
        },
        { 
            title: 'Created', 
            key: 'created_at',
            type: 'datetime',
            isSortable: true ,
            isSearchable: true
        },
        { 
            title: 'Projects', 
            key: 'projects',
            type: 'string',
            isSortable: false ,
            isSearchable: true,
            center: true
        },
        { 
            title: 'Status', 
            key: 'is_active',
            type: 'boolean',
            isSortable: true ,
            isSearchable: true,
            valueMapping: {  // New property instead of searchRankingRule
                true: 'active',
                false: 'inactive'
            }
        },
        { 
            title: '', 
            key: 'actions',
            type: 'none',
            isSortable: false ,
            isSearchable: false
        }
    ];

    /* Row rendering configuration with all position parameters
     * @param rowData - The data for this row
     * @param rowIdx - Current visual position in grid (accounts for intersecting rows)
     * @param dataIdx - Original position in data array
     */
    const renderRow = (rowData, rowIdx, dataIdx) => [
        <GridCellBoldText>{rowData.name}</GridCellBoldText>,
        <GridCellBoldText>{rowData.contact}</GridCellBoldText>,
        <GridCellSmallFadedText>{rowData.kam}</GridCellSmallFadedText>,
        <GridCellSmallFadedText>{new Date(rowData.created_at).toLocaleDateString()}</GridCellSmallFadedText>,
        <GridCellSmallFadedText>
            <ExpansiveListInVersatileGrid initialVisibleItems={2}>
                {rowData.projects?.map((project, index) => (
                    <ListItemInVersatileGrid key={index}>
                        <ProjectTag key={index}>{project}</ProjectTag>
                    </ListItemInVersatileGrid>
                ))}
            </ExpansiveListInVersatileGrid>
        </GridCellSmallFadedText>,
        <div>
            {rowData.is_active ? 
                <TagSuccess label="Active"/> : 
                <TagWarning label="Inactive"/>
            }
        </div>,
        <SmallStandardButton

            onClick={() => handleManage(rowData.id)} 

        >
            Manage
        </SmallStandardButton>
    ];

    /* Statistics header component
     * Shows client statistics
     */
    const gridHeader = (
        <div>
            <StatsContainer>
                <span css={HeaderMedium600Style}>Total Clients: {clientStats.total}</span>
                <div>   
                    <SmallNormalTag>{clientStats.active} active</SmallNormalTag>
                    <SmallNormalTag>{clientStats.inactive} inactive</SmallNormalTag>
                </div>
            </StatsContainer>
        </div>
    );

    return (

        <>
            {/* Page Header Section */}
            <HeaderContainer>
                
                    <SectionTitle>Manage Clients</SectionTitle>
                
                
                    <ThickSelectPicker 
                        placeholder="Select client"
                        defaultValue={0} 
                        size="lg" 
                        data={clients.map((client, i) => ({
                            key: i+1,
                            label: client.name,
                            value: client.id
                        }))}
                        value={selectedClient?.id}
                        onChange={handleManage}
                        style={{ width: 250 }}
                    />
                
            </HeaderContainer>


            {/* Main Content Section */}
            <SimpleAccordion defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Client List"
                    eventKey="1"
                    noCollapse
                >
                    {/* Client Creation Interface */}
                   
                    <CreateClient 
                        setRefresh={setRefresh} 
                    />

                    
                    {/* Client Data Grid */}
                    <VersatileGrid 
                        headers={headers}
                        data={clients}
                        renderRow={renderRow}
                        maxDisplayedItems={8}
                        loading={loading}
                        headerInfo={gridHeader}  

                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>
            <Footer />  
        </>
    );
};


export default ClientList;