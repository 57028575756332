import { css } from '@emotion/react';
import profileSymbol from '../../assets/icons/profile.svg';
import { circleButtonStyle } from '../ui_reusable/styled_comps/Buttons'; // Updated import
import theme from '../../styles/theme';

const AvatarIconStyle = css`
    ${circleButtonStyle}
    background-image: url(${profileSymbol});
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${theme.colors.primaryVeryLight};
`;

export default AvatarIconStyle;
