import React from 'react';
import { toaster } from 'rsuite';

/*
* Overwriting the default RS Suite toaster such that we can move its DOM position
 */
const useStandardToaster = () => {
  // Create and inject styles for RS Suite toast container
  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .rs-toast-container {
        top: 115px !important;
        left: 20% !important;
      }
      .rs-toast-container.placement-top-center {
        transform: translateX(-50%) !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const pushToast = (content, options) => {
    toaster.push(content, { placement: 'topCenter', ...options });
  };

  return { pushToast };
};

export default useStandardToaster;  