import React, { useEffect, useState }  from 'react';
import { Text, Divider, FlexboxGrid, HStack } from 'rsuite';
import Analyze from './Analyze';
import { Loading } from '../../utils/loading';

const ShowDataset = ({datasetId, units}) => {
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState({
        total_observations: 0,
        included_observations: 0,
        gaps: 0,
        largest_gap: 0,
        trend: 0,
        season: 0,
        remainder: 0,
        breaks: false,
        mean: 0,
        stddev: 0
    });

    useEffect(()=>{
        if(datasetId){
            setLoading(true);
            const getSummary = async()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/${datasetId}/analyze?action=summary`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response.status === 200){
                    const result = await response.json();
                    setSummary(result.data);
                } else {
                    setSummary({
                        total_observations: 0,
                        included_observations: 0,
                        gaps: 0,
                        largest_gap: 0,
                        trend: 0,
                        season: 0,
                        remainder: 0,
                        breaks: false,
                        mean: 0,
                        stddev: 0
                    });
                }
                setLoading(false);
            }
            getSummary();
        }
    },[datasetId]);
    
    return (
        <div>
            <div style={{ backgroundColor: "#fcf9fd", padding: "10px", borderRadius: "5px" }}>
                {
                    loading && <Loading/>
                }
                {
                    !loading && summary && <HStack spacing={20} divider={<Divider vertical />} style={{ height: 40 }}>
                        <div>
                            <Text weight="bold" align="center">Total observations</Text>
                            <Text align="center">{summary.total_observations}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Included observations</Text>
                            <Text align="center">{summary.included_observations}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Missing</Text>
                            <Text align="center">{summary.gaps > 0?summary.gaps:'None'}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Units</Text>
                            <Text align="center">{units}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Seasonality</Text>
                            <Text align="center">{(summary.season>0)?'Detected':'Not detected'}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Structural breaks</Text>
                            <Text align="center">{(summary.breaks)?'Yes':'None'}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Std deviation</Text>
                            <Text align="center">{summary.stddev}</Text>
                        </div>
                        <div>
                            <Text weight="bold" align="center">Mean</Text>
                            <Text align="center">{summary.mean}</Text>
                        </div>
                    </HStack>
                }
            </div>
            <div style={{ margin: "10px 0"}}>
                <HStack>
                    <Analyze datasetId={datasetId} action="decompose"/>
                    {
                        summary && summary.season > 0 && <Analyze datasetId={datasetId} action="season"/>
                    }
                    {
                        summary && summary.breaks && <Analyze datasetId={datasetId} action="breaks"/>
                    }
                    {
                        summary && summary.gaps > 0 && <FlexboxGrid.Item><Analyze datasetId={datasetId} action="gaps"/></FlexboxGrid.Item>
                    }
                </HStack>
            </div>
        </div>
    );
};

export default ShowDataset;
