import { css } from '@emotion/react';

/*
    primary and secondary:

    Primary: The main brand color used for key elements like buttons, links, and primary actions.
    Secondary: A complementary color used to highlight less dominant elements or provide accents.
    background and surface:

    Background: The base color for the main application background.
    Surface: The color for components or cards that appear on top of the background.
    error:

    Used for error states, alerts, or validation messages.
    onPrimary, onSecondary, etc.:

    Colors that are meant to appear on top of their respective base colors.
    Example:
    If primary is a dark color (#6200ea), onPrimary will likely be light (#ffffff) to maintain contrast.
    Similarly, onBackground defines the text or content color that appears over the background.
*/


const theme = {
  colors: {
    primary: '#7F56D9',
    primaryLight: '#E9D7FE',
    primaryVeryLight: '#F9F5FF',
    primaryFaded: '#9E77ED',
    //not set yet
    secondary: '#000000', //not set yet
    background: '#f2f4f7',
    backgroundTwilight: '#f1f1f1',
    backgroundDark: '#dcdcdc',
    backgroundVeryDark: '#a9a9a9',
    standardText: '#000000',
    fadedText: '#101828',
    veryFadedText:'#475467',
    veryVeryFadedText:'#7b8aa2',
    surface: '#ffffff',//not set yet
    surfaceSecondary: '#F9FAFB',
    error: '#b92e2e',//not set yet
    onPrimary: '#000000',//not set yet
    onSecondary: '#000000',//not set yet
    onBackground: '#000000',//not set yet
    onSurface: '#000000',//not set yet
    onError: '#000000',//not set yet
  },
  shadows: {
    light: '0 2px 5px rgba(0, 0, 0, 0.05)',
    medium: '0 4px 8px rgba(0, 0, 0, 0.1)',
    heavy: '0 8px 16px rgba(0, 0, 0, 0.15)',
  },
};

export default theme;

