import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, Message, Panel, Text } from 'rsuite';
import useStandardToaster from '../ui_reusable/StandardToaster';

const AcceptInvitation = () => {
  const { token } = useParams();
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: ''
  });
  const { pushToast } = useStandardToaster();
  const navigate = useNavigate();

  useEffect(() => {
    const getInvitation = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/invitations/${token}/`, { 
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        });
        const result = await response.json();
        if(response.status === 200){
            setData(result.data);
        } else {
            setError(result.error);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getInvitation();
  }, [token]);

  const handleSubmit = async() =>{
    if (formData.password !== formData.confirm_password){
        pushToast(
            <Message type="error">
                {'Password mismatch'}
            </Message>,
            { placement: 'topCenter', duration: 5000 }
        );
    } else {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/invitations/${token}/`, { 
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({password: formData.password})
            });
            const result = await response.json();
            if(response.status === 200){
                pushToast(
                    <Message type="success">
                        {'Thank you, try login.'}
                    </Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                navigate('/login');
            } else {
                pushToast(
                    <Message type="error">
                        {result.error}
                    </Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            }
        } catch (error) {
            pushToast(
                <Message type="error">
                    {error.message}
                </Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    }
  }

  return (
        <Container>
            <Panel style={{ width: '370px', margin: '50px auto'}} bordered>
                {
                    error && <Message type='error'>{error}</Message>
                }
                {
                    !error && data && <div>
                        <div style={{ margin: "20px 0"}}><Text weight='bold' size='xxl'>Citrus | Sign up</Text></div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control name="email" type="email" value={data?.email} readOnly/>
                                <Form.HelpText tooltip>Email is required</Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control name="password" type="password" onChange={(value)=>setFormData(prev => ({...prev, password: value }))} autoComplete="off" />
                            </Form.Group>
                            <Form.Group controlId="confirm_password">
                                <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                <Form.Control name="confirm_password" type="password" onChange={(value)=>setFormData(prev => ({...prev, confirm_password: value }))} autoComplete="off" />
                            </Form.Group>
                            <Form.Group>
                                <Button appearance="primary" type='submit'>Submit</Button>
                            </Form.Group>
                        </Form>
                    </div>
                }
            </Panel>
        </Container>
    );
};

export default AcceptInvitation;
