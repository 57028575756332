import { useNavigate } from "react-router-dom";
import { Button, Container, Panel, Stack, Text } from "rsuite";
import { useProjectContext } from "../../context/ProjectContext";
import { useEffect, useState } from "react";

const ModelDashboard = ()=>{
    const navigate = useNavigate();
    const {selectedProject} = useProjectContext();   
    const [projectSolutions, setProjectSolutions] = useState([]);

    useEffect(()=>{
        const getProjectSolutions = async () =>{
            if(selectedProject){
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject.id}/solutions`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                const res = await response.json();
                setProjectSolutions(res.data);
            }
        }
        getProjectSolutions();
    },[selectedProject]);

    return (
        <Container style={{ margin: "20px 0" }}>
            {
                projectSolutions && projectSolutions.length > 0 && projectSolutions.map((projectSolution, i)=>{
                    return (
                        <Panel key={i+1} style={{ margin: "10px" }} bordered>
                            <Stack justifyContent="space-between">
                                <span><Text weight="bold" size="xxl" transform="uppercase">{projectSolution.solution.name}</Text></span>
                                <Button appearance="primary" color="violet" onClick={()=>navigate(`/models/build`, {state: {solutionId: projectSolution.solution.id}})} disabled={!projectSolution.is_active}>Build model</Button>
                            </Stack>
                        </Panel>
                    );
                })
            }
        </Container>
    )
}
export default ModelDashboard;