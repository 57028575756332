import styled from '@emotion/styled';
import { Toggle } from 'rsuite';

export const StandardToggleButton = styled(Toggle)`
  margin: 0.5rem;

  &.rs-toggle {
    --rs-toggle-checked-bg: #33c03a !important;
    --rs-toggle-checked-hover-bg: #1b5e20 !important;
    --rs-toggle-bg: #d3d3d3 !important;
    --rs-toggle-hover-bg: #a9a9a9 !important;
  }
`;

// Add check mark by default
StandardToggleButton.defaultProps = {
    size: "md",
    checkedChildren: "✓"
};
