import { css } from '@emotion/react/macro';
import theme from './theme';
export const DocumentStyles = css`
        html {
          font-size: 100%;
        }
  
        body {
          width: 100vw;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${theme.colors.background};
        }
  
        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }
        
        .top {
      
          width: 100vw;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          
        }

                /* Heading elements reset */
        h1, h2, h3, h4, h5, h6 {
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          box-sizing: border-box; /* Ensure consistent box model */
        }


      `

      //;