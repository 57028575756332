import React from 'react';
import { Button, Checkbox, CheckboxGroup, Container, FlexboxGrid, HStack, Panel, Radio, RadioGroup, SelectPicker, Tag, Text, VStack } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import ReportAccordion from '../ui_reusable/ReportAccordion';
import ExecutiveSummary from './Report/ExecutiveSummary';
import ModelPerformance from './Report/ModelPerformance';
import DriverImportance from './Report/DriverImportance';
import DriverImpact from './Report/DriverImpact';
import SingleDriver from './Report/SingleDriver';
import MyComments from './Report/MyComments';
import SimpleAccordion from '../ui_reusable/SimpleAccordion'; // Add this import

const CheckIcon = ({ size, color }) => <CheckRoundIcon color={color} style={{ fontSize: size, marginRight: 10 }} />;

const Report = () => {
    const dummyAccordionData = [
        {
            title: "Model Performance Overview",
            content: "The model shows strong performance with an R-squared value of 0.84 and consistent prediction accuracy across different segments."
        },
        {
            title: "Key Drivers Analysis",
            content: "Primary drivers include market sentiment (40% impact), economic indicators (30% impact), and seasonal patterns (20% impact)."
        },
        {
            title: "Recommendations",
            content: "Based on the current model performance, we recommend focusing on enhancing the market sentiment tracking and improving seasonal adjustment factors."
        }
    ];

    // Define report sections with their components and headers
    const reportSections = [
        { 
            header: "Executive Summary",
            component: <ExecutiveSummary />,
            helpText: (
                <>
                    <p>The executive summary provides a high-level overview of the model's performance and key findings.</p>
                    <p>Use this section to quickly understand the model's main results and recommendations.</p>
                </>
            )
        },
        { 
            header: "Model Performance",
            component: <ModelPerformance />,
            helpText: (
                <>
                    <p>Detailed analysis of model accuracy and reliability metrics.</p>
                    <p>Key metrics include R-squared, RMSE, and prediction accuracy across different segments.</p>
                </>
            )
        },
        { 
            header: "Driver Importance",
            component: <DriverImportance />,
            helpText: (
                <>
                    <p>Shows the relative importance of different factors affecting the model.</p>
                    <p>Higher percentages indicate stronger influence on the outcome.</p>
                </>
            )
        },
        { 
            header: "Driver Impact",
            component: <DriverImpact />,
            helpText: "Analyze how each driver affects the model's predictions and their relationships with other variables."
        },
        { 
            header: "Single Driver Analysis",
            component: <SingleDriver />,
            helpText: "Deep dive into individual driver behavior and its specific impact on the model."
        },
        { 
            header: "My Comments",
            component: <MyComments />,
            helpText: "Add your personal notes and observations about the model's performance and recommendations."
        }
    ];

    // Add simple sections data
    const simpleSections = [
        {
            header: "Simple Section 1",
            content: "This is a basic accordion panel with minimal styling and a single expand/collapse button."
        },
        {
            header: "Simple Section 2",
            content: "Compare this simple version with the full report version above to see the styling differences."
        },
        {
            header: "Simple Section 3",
            content: "The SimpleAccordion is perfect for basic content that doesn't need extra controls or help text."
        }
    ];

    return (
        <Container style={{ margin: "20px" }}>
            <HStack style={{ marginBottom: "20px" }}>
                <Text size="xxl">Model name</Text>
                <Button appearance="subtle"><EditIcon/></Button>
            </HStack>
            <div className="show-grid" style={{ marginBottom: "20px" }}>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={6} style={{ paddingRight: "10px" }}>
                        <Panel bordered style={{backgroundColor: 'white'}}>
                            <Text>Solution</Text>
                            <Text weight="bold" size="xxl">Tracker</Text>
                        </Panel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6} style={{ paddingRight: "10px" }}>
                        <Panel bordered style={{backgroundColor: 'white'}}>
                            <Text>Run date</Text>
                            <Text weight="bold" size="xxl">{new Date().toLocaleDateString()}@{new Date().toLocaleTimeString()}</Text>
                        </Panel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6} style={{ paddingRight: "10px" }}>
                        <Panel bordered style={{backgroundColor: 'white'}}>
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item colspan={20}>
                                    <Text>Model fit score</Text>
                                    <Text weight="bold" size="xxl">84</Text>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>
                                    <CheckIcon size="3em" color="green"/>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Panel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}>
                        <Panel bordered style={{backgroundColor: 'white'}}>
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item colspan={20}>
                                    <Text>Model health score</Text>
                                    <Text weight="bold" size="xxl">7/10</Text>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>
                                    <CheckIcon size="3em" color="orange"/>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
            <Panel header="Set up report" bordered style={{ marginBottom: "20px",backgroundColor: 'white' }}>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Text weight="bold">Report level</Text>
                        <RadioGroup name="radio-group">
                            <Radio>Executive</Radio>
                            <Radio>Advanced</Radio>
                            <Radio>Custom</Radio>
                            <SelectPicker disabled searchable={false} cleanable={false} defaultValue={0} data={[{key: 0, label: 'Choose template', value: 0}]}/>
                        </RadioGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Text weight="bold">Include Plus</Text>
                        <CheckboxGroup name="checkbox-group">
                            <Checkbox value="A">AI+</Checkbox>
                            <Checkbox value="B">KPI+</Checkbox>
                            <Checkbox value="C">Segment+</Checkbox>
                            <Checkbox value="D">Geo+</Checkbox>
                        </CheckboxGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Text weight="bold">Confidence level</Text>
                        <RadioGroup name="radio-group">
                            <Radio>High</Radio>
                            <Radio>Normal</Radio>
                            <Radio>Lowest allowed</Radio>
                        </RadioGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Text weight="bold">Report contains</Text>
                        <VStack>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>Executive summary</Tag>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>Model performance</Tag>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>Driver importance</Tag>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>Driver impact</Tag>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>Prognosis</Tag>
                            <Tag style={{ borderRadius: "50px", border: "1px solid violet", padding: "5px 30px", width: "180px" }}>My comments</Tag>
                        </VStack>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
            
            <ReportAccordion>
                {reportSections.map((section, index) => (
                    <ReportAccordion.Panel 
                        key={index}
                        eventKey={index}
                        header={section.header}
                        helpText={section.helpText}
                    >
                        {section.component}
                    </ReportAccordion.Panel>
                ))}
            </ReportAccordion>

            {/* Add divider and title for comparison section */}
            <div style={{ margin: "40px 0 20px" }}>
                <Text size="lg">Simple Accordion Example (for comparison)</Text>
            </div>

            <SimpleAccordion>
                {simpleSections.map((section, index) => (
                    <SimpleAccordion.Panel
                        key={index}
                        eventKey={index}
                        header={section.header}
                    >
                        <div style={{ padding: "20px" }}>
                            {section.content}
                        </div>
                    </SimpleAccordion.Panel>
                ))}
            </SimpleAccordion>
        </Container>
    );
}
export default Report;