import React from 'react';
import {
  default as StyledCheckmark,
  Container,
  SVGWrapper,
  Circle,
  Check,
  Text
} from './styled_comps/CheckmarkAnimation';

/**
 * Animated checkmark component that shows a circle drawing followed by a checkmark
 * @param {Object} props
 * @param {boolean} props.show - Controls visibility of the animation
 * @param {string} props.className - Optional className for styling the container
 * @param {string} props.text - Optional text to display below the checkmark
 */
const CheckmarkAnimation = ({ show = false, className, text }) => {
  return (
    <StyledCheckmark show={show} className={className}>
      <Container>
        <SVGWrapper viewBox="0 0 100 100">
          <Circle show={show} cx="50" cy="50" r="45"/>
          <Check show={show} d="M25,50 L45,70 L75,30"/>
        </SVGWrapper>
        {text && <Text show={show}>{text}</Text>}
      </Container>
    </StyledCheckmark>
  );
};

export default CheckmarkAnimation;

// Usage example:
// <CheckmarkAnimation show={true} text="Success!" />
