import React from 'react';
import { ThickSelectPickerStyled, FormSelectPickerStyled } from './styles/StandardSelectPickerStyles';

const ThickSelectPicker = ({ ...props }) => {
    return (
        <ThickSelectPickerStyled 
            {...props}
            cleanable={false}
            searchable={false}
        />
    );
};

const FormSelectPicker = ({ ...props }) => {
    return (
        <FormSelectPickerStyled 
            {...props}
            cleanable={false}
            searchable={false}
        />
    );
};

export { ThickSelectPicker, FormSelectPicker };
