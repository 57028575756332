import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import {TextMediumMini500Style} from '../../styles/TextStyles';

export const FilePanel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const DragZone = styled.div`
    background-color: ${props => props.isDragging ? '#f5f5f5' : '#ffffff'};
    border: 2px dashed ${props => props.isDragging && !props.hasFile ? theme.colors.primary : theme.colors.backgroundDark};
    border-radius: 6px;
    width: 400px;
    padding: 0.75rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.hasFile ? 'default' : 'pointer'};
    transition: all 0.3s ease;
    
    &:hover {
        border-color: ${props => props.hasFile ? 
            theme.colors.backgroundDark : 
            theme.colors.primary
        };
    }
`;

export const FileInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.25rem;
    background: ${theme.colors.backgroundLight};
    border-radius: 4px;
    width: 100%;
`;

export const FileName = styled.span`
    ${TextMediumMini500Style}
    color: ${theme.colors.backgroundVeryDark};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;
`;

export const UploadText = styled.p`
    ${TextMediumMini500Style}
    color: ${theme.colors.backgroundVeryDark};
    margin: 0;
    line-height: 1.5;
    padding: 0.25rem;
`;

export const RemoveButton = styled.button`
    background: none;
    border: none;
    color: ${theme.colors.error};
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }
`;

export const FileContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
