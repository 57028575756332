import React, { useState } from "react";
import { Modal, Toggle } from "rsuite";
import CustomIcons from '../../ui_reusable/CustomIcons';

import { GhostIconButtonRed, WarningButton, DangerButton, GhostButton } from '../../ui_reusable/styled_comps/Buttons';
import { StandardToggleButton } from '../../ui_reusable/styled_comps/ToggleButtons';
import {
    WarningSection,
    OptionsSection,
    TwoColumnMainContainer,  // Changed from ThreeColumnContainer
    Column,
    TwoColumnContainer,
    ControlColumn,
    ContentColumn,
} from '../../ui_reusable/styled_sub_comps/DeactivateStyledSubComps';
import {HeaderSmall600Style,HeaderLargeImportant,TextMediumMaxi600Style,HeaderMedium600Style,TextMediumMini500Style,TextSmall500FadedStyle} from '../../styles/TextStyles';
import StandardModal from '../../ui_reusable/StandardModal';
import theme from '../../../styles/theme';

const Deactivate = () => {
    const [isPreserveAccess, setIsPreserveAccess] = useState(true);
    const [isActive, setIsActive] = useState(true); // This will come from API
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteUnderstandModal, setShowDeleteUnderstandModal] = useState(false);

    const handleActiveToggle = () => {
        if (isActive) {
            setShowDeactivateModal(true);
        } else {
            // Handle activation logic
            setIsActive(true);
        }
    };

    const handleDeleteClick = () => {
        setShowDeleteUnderstandModal(true);
    };

    const handleUnderstandClick = () => {
        setShowDeleteUnderstandModal(false);
        setShowDeleteModal(true);
    };

    return (
        <>
            <WarningSection>
                <h2 css={HeaderLargeImportant}>DANGER ZONE</h2>
                <span css={TextMediumMaxi600Style}>
                    Please be very careful and very sure before making changes here.
                </span>
            </WarningSection>

            <OptionsSection>
                <TwoColumnMainContainer>  {/* Changed from ThreeColumnContainer */}
                    {/* Active Status Column */}
                    <Column>
                        <TwoColumnContainer>
                            <ControlColumn>
                                <StandardToggleButton
                                    checked={isActive}
                                    onChange={handleActiveToggle}
                                />
                            </ControlColumn>
                            <ContentColumn>
                                <h4 
                                    css={HeaderMedium600Style} 
                                    style={{color: isActive ? "#c5841c" : '#A0A0A0'}}
                                >
                                    Active status
                                </h4>
                                <span 
                                    css={TextSmall500FadedStyle} 
                                    style={{color: isActive ? "#c5841c" : '#A0A0A0'}}
                                >
                                    Click to deactivate client from Citrus.
                                    <br />
                                    This can be reverted later.
                                </span>
                            </ContentColumn>
                        </TwoColumnContainer>
                    </Column>

                    {/* Delete Column */}
                    <Column>
                        <TwoColumnContainer>
                            <ControlColumn>
                                <GhostIconButtonRed 
                                     onClick={handleDeleteClick}
                                >
                                    <CustomIcons.TrashIcon size="small" color="#ff0000"/>
                                </GhostIconButtonRed>
                            </ControlColumn>
                            <ContentColumn>
                                <h4 css={HeaderMedium600Style} style={{color: "#b71c1c"}}>Delete client</h4>
                                <span css={TextSmall500FadedStyle} style={{color: "#b71c1c"}}>
                                    Click to delete client from Citrus. This action cannot be undone.
                                </span>
                            </ContentColumn>
                        </TwoColumnContainer>
                    </Column>
                </TwoColumnMainContainer>
            </OptionsSection>

            {/* Deactivate Modal */}
            <StandardModal 
                open={showDeactivateModal} 
                onClose={() => setShowDeactivateModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Deactivate Client?</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    Are you sure you want to deactivate this client? The client with all
                    its users will lose access to all models unless the client is activated
                    again.
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="Deactivate"
                    cancelText="Cancel"
                    confirmColor="#f5a623"
                    onConfirm={() => {
                        setIsActive(false);
                        setShowDeactivateModal(false);
                    }}
                    onCancel={() => setShowDeactivateModal(false)}
                />
            </StandardModal>

            {/* Delete Understanding Modal */}
            <StandardModal 
                open={showDeleteUnderstandModal} 
                onClose={() => setShowDeleteUnderstandModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Do you understand?</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    Hold on. You are about to delete a client from Citrus. Do you understand 
                    that this action cannot be undone?
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="I understand"
                    cancelText="Take me back"
                    confirmColor="#b71c1c"
                    onConfirm={handleUnderstandClick}
                    onCancel={() => setShowDeleteUnderstandModal(false)}
                    flip={true}
                />
            </StandardModal>

            {/* Delete Modal */}
            <StandardModal 
                open={showDeleteModal} 
                onClose={() => setShowDeleteModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title style={{ color: "#b71c1c" }}>
                        Delete Client?
                    </StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    <div>
                        Do you want to delete the client? The client with all its users will
                        lose access to all models. Are you sure about this?
                    </div>
                    
                    {/* Preserve Access Section */}
                    <TwoColumnContainer style={{ marginTop: '2rem' }}>
                        <ControlColumn>
                            <StandardToggleButton
                                checked={isPreserveAccess}
                                onChange={() => setIsPreserveAccess(!isPreserveAccess)}
                            />
                        </ControlColumn>
                        <ContentColumn>
                            <h4 css={HeaderMedium600Style}>Preserve access</h4>
                            <span css={TextSmall500FadedStyle}>
                                Allow active users to maintain access to their saved reports. If unchecked, individual users will permanently lose access to all
                                logs, history, and datasets.
                            </span>
                        </ContentColumn>
                    </TwoColumnContainer>
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="Delete client"
                    cancelText="Take me back"
                    confirmColor="#b71c1c"
                    onConfirm={() => setShowDeleteModal(false)}
                    onCancel={() => setShowDeleteModal(false)}
                />
            </StandardModal>
        </>
    );
};

export default Deactivate;
