import styled from '@emotion/styled';
import { SelectPicker } from 'rsuite';
import theme from '../../../styles/theme';

// Shared styling for SelectPickers
const sharedSelectPickerStyles = `
    width: 10rem!important;
    min-width: 30% !important;
    .rs-picker-toggle.rs-btn {
        background: ${theme.colors.surface} !important;
        border: 1px solid #D1D5DB !important;
        border-radius: 8px !important;
        padding: 8px 12px !important;
        &:hover, &:focus {
            border-color: ${theme.colors.primary} !important;
        }
    }

    .rs-picker-toggle-indicator {
        color: #6B7280 !important;
    }

    &.rs-picker-focused {
        .rs-picker-toggle {
            border-color: ${theme.colors.primary} !important;
            box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1) !important;
        }
    }
`;

export const ThickSelectPickerStyled = styled(SelectPicker)`
    ${sharedSelectPickerStyles}

    .rs-picker-toggle-textbox,
    .rs-picker-toggle-placeholder {
        font-weight: 600 !important;
        color: #374151 !important;
        font-size: 1rem !important;
    }
`;

export const FormSelectPickerStyled = styled(SelectPicker)`
    ${sharedSelectPickerStyles}

    .rs-picker-toggle-textbox,
    .rs-picker-toggle-placeholder {
        font-weight: 400 !important;
        color: #374151 !important;
        font-size: 0.8rem !important;
    }
`;
