
import styled from '@emotion/styled';
import { baseAccordionContainer, baseAccordionHeader, PanelStyle } from '../../styles/PanelStyle';
import theme from '../../../styles/theme';

export const AccordionContainer = styled.div`
  ${baseAccordionContainer}
  gap: 45px;
`;

export const PanelContainer = styled.div`
  ${PanelStyle}
  box-shadow: ${theme.shadows.medium};
`;

export const PanelHeader = styled.div`
  ${baseAccordionHeader}
`;

export const CustomHStack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;