import styled from '@emotion/styled';

// Vertical stack container
export const VStack = styled.div`
    display: flex;
    flex-direction: column;
`;

// Horizontal stack container
export const HStack = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
`;

// Horizontal stack container
export const VStackBroad = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
