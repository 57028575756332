import styled from '@emotion/styled';
import { Modal } from 'rsuite';
import { TextLarge500Style,HeaderLarge500Style, TextSmall500FadedStyle } from '../../styles/TextStyles';

export const StyledModal = styled(Modal)`
  .rs-modal-content {
    border-radius: 12px;
    padding: 1rem;
  }

  .rs-modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.7rem;
    border-bottom: none;

    .rs-modal-title {
      ${HeaderLarge500Style}
    }
  }

  .rs-modal-body {
    ${TextSmall500FadedStyle}
    padding: 0.0rem 0.7rem;
  }

  .rs-modal-footer {
    padding: 1.8rem 1rem 0.2rem 1rem;
    border-top: none;
    display: flex;
    justify-content: center;  // Changed from flex-end to center
    gap: 1rem;
  }
`;

export const StyledModalHeader = styled(Modal.Header)``;
export const StyledModalBody = styled(Modal.Body)``;
export const StyledModalFooter = styled(Modal.Footer)`
  padding: 1.8rem 1rem 0.2rem 1rem;
  border-top: none;
  display: flex;
  justify-content: center;  // Changed from flex-end to center
  gap: 1rem;
`;
