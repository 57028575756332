export const quickSelectionDateOptions = (startDate, endDate) => {
  return [
    {
      key: 1,
      label: 'First 3 year',
      value: [new Date(startDate), new Date(new Date(startDate).setFullYear(new Date(startDate).getFullYear() + 3))]
    },
    {
      key: 2,
      label: 'Last 3 year',
      value: [new Date(new Date(endDate).setFullYear(new Date(endDate).getFullYear() - 3)), new Date(endDate)]
    },
    {
      key: 3,
      label: 'All',
      value: [new Date(startDate), new Date(endDate)]
    }
  ];
}

export const prognosisOptions = [
  {key:1, value:0, label:'No prognosis (default)'},
  {key:2, value:3, label:'3 months ahead'},
  {key:3, value:6, label:'6 months ahead'},
  {key:4, value:12, label:'12 months ahead'},
]

