import styled from '@emotion/styled';
import { TextLarge500Style } from '../../../styles/TextStyles';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`;

export const SettingsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const InfoSection = styled.div`
    margin-top: 3rem;

`;

export const LogoSection = styled.div`
    max-width: 500px;
`;
