import React, { createContext, useContext, useEffect, useState } from "react";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const storedProject = localStorage.getItem("project");
    if (storedProject) {
      setSelectedProject(JSON.parse(storedProject));
    }
  }, []);

  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem("project", JSON.stringify(selectedProject)); 
    }
  }, [selectedProject]);
  
  return (
    <ProjectContext.Provider value={{ selectedProject, setSelectedProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  return useContext(ProjectContext);
};