import React  from 'react';
import { Modal, Button, Heading, Text } from 'rsuite';

const FileValidationModal = ({open, handleClose}) => {
  return (
        <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="md">
            <Modal.Body>
                <Heading>How your excel file should be formatted?</Heading>
                <Text>Before uploading your excel files, please make sure it follows the required format:</Text>
                <br/>
                <Text><b>Column 1: </b>Date(Format: YYYY-MM-DD)</Text>
                <Text><b>Column 2: </b>Measures(e.g., Quality, Value)</Text>
                <Text><b>Remaining Columns: </b>Dimensions(e.g., Location, Category...)</Text>
                <br/>
                <Text>Examples:</Text>
                <pre>
                    <p>Date        | Value | Location  | Quantity</p>
                    <p>2024-01-01  | 500   | Norway    | 100</p>
                    <p>2024-01-02  | 750   | Sweden    | 150</p>
                </pre>
                <Text>This is an example excel file, click <a href="/">here</a> to download.</Text>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} appearance="primary" color='violet'>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FileValidationModal;
