import React, { useRef, useEffect, useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Uploader, Message, Input, InputGroup } from 'rsuite';
import Footer from "./Footer";
import SectionTitle from './ui_reusable/SectionTitle';
import {StandardButton} from "./ui_reusable/styled_comps/Buttons";
import { PanelSpecialIndent } from "./ui_reusable/styled_comps/PlacementMisc";
import useStandardToaster from './ui_reusable/StandardToaster'; // Updated import
import { TextLarge500Style, InfoMediumMaxi600Style,HeaderMedium500Style } from './styles/TextStyles';
import { VerySmallNormalTag} from "./ui_reusable/styled_comps/Tags";
import { StandardInput } from './ui_reusable/StandardInputs';
import CustomIcons from './ui_reusable/CustomIcons';

import EyeCloseIcon from '@rsuite/icons/EyeClose';
import VisibleIcon from '@rsuite/icons/Visible';
import {DataVariablePresentation} from './ui_reusable/styles/DataInfoUtils';
import SimpleAccordion from "./ui_reusable/SimpleAccordion";
import {ThreeColumnGrid} from "./ui_reusable/styled_comps/PlacementMisc";
import {
  TwoColumnsInPanel,
  Form,
  Button,
  FlexContainer,
  InputWrapper,
  AvatarStylizedContainer,
  ButtonContainerInline,
  PasswordPanel // Add this import
} from './styled_sub_comps/ProfileStyledSubComps'; 


// Create a separate EditableField component with local state
const EditableField = memo(({ isEditing, initialValue, onSave, type = "text" }) => {
    const [localValue, setLocalValue] = useState(initialValue);
    
    useEffect(() => {
        setLocalValue(initialValue);
    }, [initialValue, isEditing]);

    const handleChange = (e) => {
        setLocalValue(e.target.value);
    };

    const handleBlur = () => {
        if (isEditing && localValue !== initialValue) {
            onSave(localValue);
        }
    };

    return (
        <InputWrapper>
            <StandardInput
                type={type}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isEditing}
            />
        </InputWrapper>
    );
});

const Profile = () => {
    const [userData, setUserData] = useState(null);

    const [refresh, setRefresh] = useState(new Date().getTime());
    const [isEditingName, setEditName] = useState(false);
    const [name, setName] = useState("");
    
    const [isEditingEmail, setEditEmail] = useState(false);
    const [email, setEmail] = useState("");

    const [isEditingPhone, setEditPhone] = useState(false);
    const [phone, setPhone] = useState("");

    const { pushToast } = useStandardToaster(); // Updated toaster usage
    const [uploading, setUploading] = useState(false);
    const [uploadKey, setUploadKey] = useState(0); // Add this new state

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState(false);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    // Add these new state variables to track original values
    const [originalName, setOriginalName] = useState("");
    const [originalEmail, setOriginalEmail] = useState("");
    const [originalPhone, setOriginalPhone] = useState("");

    const [fieldErrors, setFieldErrors] = useState({
        email: false,
        phone: false
    });

    const emailInputRef = useRef(null);
    const phoneInputRef = useRef(null);

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPhone = (phone) => {
        return /^[\d\s+()-]{8,}$/.test(phone);
    };

    useEffect(() => {
        const getUserInfo = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const res = await response.json();
            const data = res.data;
            setUserData(data);
            console.log("userData",data);
            // Set both current and original values
            setName(data.name);
            setEmail(data.email);
            setPhone(data.phone);
            
            // Store original values
            setOriginalName(data.name);
            setOriginalEmail(data.email);
            setOriginalPhone(data.phone);
        }
        getUserInfo();
    }, [refresh]);

    const hasChanges = () => {
        return name !== originalName ||
               email !== originalEmail ||
               phone !== originalPhone;
    };

    const updateProfile = async () => {
        setFieldErrors({ email: false, phone: false });
        let hasErrors = false;

        if (email && !isValidEmail(email)) {
            if (emailInputRef.current) emailInputRef.current();
            setFieldErrors(prev => ({ ...prev, email: true }));
            pushToast(<Message type="error">Please enter a valid email address</Message>, 
                { placement: 'topCenter', duration: 5000 });
            hasErrors = true;
        }

        if (phone && !isValidPhone(phone)) {
            if (phoneInputRef.current) phoneInputRef.current();
            setFieldErrors(prev => ({ ...prev, phone: true }));
            pushToast(<Message type="error">Please enter a valid phone number</Message>, 
                { placement: 'topCenter', duration: 5000 });
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        // Only update if there are actual changes
        if (!hasChanges()) {
            pushToast(<Message type="info">No changes to update</Message>, { placement: 'topCenter', duration: 5000 });
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            method: 'PATCH',
            body: JSON.stringify({

                name: name.trim(),

                email,
                phone
            })
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Profile updated successfully</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update profile</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleFileChange = async (files) => {
        if (!files || !files[0]) return;
        
        setUploading(true);
        const formData = new FormData();
        formData.append('avatar', files[0].blobFile);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.status === 200) {
                // Force re-mount of Uploader by changing key
                setUploadKey(prev => prev + 1);
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Uploaded successfully</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            pushToast(<Message type="error">Upload failed</Message>, { placement: 'topCenter', duration: 5000 });
            console.error('Failed to upload avatar:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleUpdatePassword = async (event) => {
        event.preventDefault();
        setMessage('');
        setError(false);

        const requestData = {
            old_password: oldPassword,
            new_password: newPassword,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/change_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setMessage('Password updated successfully!');
                setOldPassword('');
                setNewPassword('');
            } else {
                const errorData = await response.json();
                if (errorData.message.old_password || errorData.message.new_password) {
                    setValidationErrors(errorData.message);
                } else {
                    setMessage(errorData.message || 'Failed to update password');
                    setError(true);
                }
            }
        } catch (err) {
            setMessage('An error occurred. Please try again later.');
            setError(true);
        }
    }

    return (
        <>
            <SectionTitle >Profile settings</SectionTitle>
            <SimpleAccordion>
                <SimpleAccordion.Panel noCollapse>
                    <PanelSpecialIndent>
                        {userData  && (
                            <TwoColumnsInPanel>
                                <ThreeColumnGrid>
                                    <span css={InfoMediumMaxi600Style}>Name:</span>
                                    <EditableField 
                                        isEditing={isEditingName}
                                        initialValue={name}
                                        onSave={setName}
                                    />
                                    <Button onClick={() => {
                                        if (isEditingName) {
                                            updateProfile();
                                        }
                                        setEditName(!isEditingName);
                                    }}>
                                        {!isEditingName ? <CustomIcons.PenIcon size="small" /> : 
                                            <CustomIcons.faSave color="var(--icon-color)" />}
                                    </Button>

                                    <span css={InfoMediumMaxi600Style}>Email:</span>
                                    <EditableField 
                                        isEditing={isEditingEmail}
                                        initialValue={email}
                                        onSave={setEmail}
                                        type="email"
                                    />
                                    <Button onClick={() => {
                                        if (isEditingEmail) {
                                            updateProfile();
                                        }
                                        setEditEmail(!isEditingEmail);
                                    }}>
                                        {!isEditingEmail ? <CustomIcons.PenIcon size="small" /> : 
                                            <CustomIcons.faSave color="var(--icon-color)" />}
                                    </Button>

                                    <span css={InfoMediumMaxi600Style}>Phone:</span>
                                    <EditableField 
                                        isEditing={isEditingPhone}
                                        initialValue={phone}
                                        onSave={setPhone}
                                    />
                                    <Button onClick={() => {
                                        if (isEditingPhone) {
                                            updateProfile();
                                        }
                                        setEditPhone(!isEditingPhone);
                                    }}>
                                        {!isEditingPhone ? <CustomIcons.PenIcon size="small" /> : 
                                            <CustomIcons.faSave color="var(--icon-color)" />}
                                    </Button>
                                    <span css={InfoMediumMaxi600Style}>Roles:</span>
                                    
                                    <div>
                                        {userData?.role && < VerySmallNormalTag style={{fontWeight: 600}} >{userData.role.toUpperCase()}</VerySmallNormalTag>}
                                    </div>


                                </ThreeColumnGrid>
                                <div>
                                    <FlexContainer>
                                        <AvatarStylizedContainer avatarUrl={userData && userData.avatar ? `${process.env.REACT_APP_API_URL}/${userData.avatar}` : null}/>
                                        <Uploader
                                            key={uploadKey}
                                            autoUpload={false}
                                            fileListVisible={false}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={uploading}
                                            cleanable={false}
                                            multiple={false}
                                        >
                                            <StandardButton disabled={uploading}>
                                                {'Upload avatar'}
                                            </StandardButton>
                                        </Uploader>
                                    </FlexContainer>
                                </div>
                            </TwoColumnsInPanel>

                        )}
                        <div className="mt-20">
                            <StandardButton onClick={() => setShowChangePassword(!showChangePassword)} >Change password</StandardButton>
                            {showChangePassword && (
                                <PasswordPanel> 
                                    {message && <Message type={error ? 'error' : 'success'}>{message}</Message>}
                                    <Form onSubmit={handleUpdatePassword}>
                                        <div className="input-group">
                                            <label css={DataVariablePresentation}>Old password</label>
                                            <InputGroup inside>
                                                <Input 
                                                    name="old_password" 
                                                    size="sm" 
                                                    type={showOldPassword ? 'text' : 'password'} 
                                                    placeholder="Old password" 
                                                    value={oldPassword} 
                                                    onChange={(value) => setOldPassword(value)} 
                                                    required 
                                                />
                                                <InputGroup.Button onClick={() => setShowOldPassword(!showOldPassword)}>
                                                    {showOldPassword ? <VisibleIcon /> : <EyeCloseIcon />}
                                                </InputGroup.Button>
                                            </InputGroup>
                                            {validationErrors.old_password && (
                                                <div className="d-block">
                                                    {validationErrors.old_password[0]}
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-group">
                                            <label css={DataVariablePresentation}>New password</label>
                                            <InputGroup inside>
                                                <Input 
                                                    name="new_password" 
                                                    size="sm" 
                                                    type={showNewPassword ? 'text' : 'password'} 
                                                    placeholder="New password" 
                                                    value={newPassword} 
                                                    onChange={(value) => setNewPassword(value)} 
                                                    required 
                                                />
                                                <InputGroup.Button onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    {showNewPassword ? <VisibleIcon /> : <EyeCloseIcon />}
                                                </InputGroup.Button>
                                            </InputGroup>
                                            {validationErrors.new_password && (
                                                <div className="d-block">
                                                    {validationErrors.new_password[0]}
                                                </div>
                                            )}
                                        </div>
                                        <ButtonContainerInline>
                                            <StandardButton type="submit">Submit</StandardButton>
                                        </ButtonContainerInline>
                                    </Form>
                                </PasswordPanel>
                            )}
                        </div>
                    </PanelSpecialIndent>
                </SimpleAccordion.Panel>
            </SimpleAccordion>
            <Footer />  
        </>
    );
}
export default Profile;