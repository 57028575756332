import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../ui_reusable/SectionTitle";
import { Button, Checkbox, Container, DateRangePicker, FlexboxGrid, HStack, IconButton, Input, InputGroup, Message, Panel, RadioTile, RadioTileGroup, SelectPicker, Table, Tag, Text, useMediaQuery } from "rsuite";
import {TagDefault} from '../../../../utils/tag';
import SearchIcon from '@rsuite/icons/Search';
import { IconDanger, IconSuccess, IconWarning } from "../../../../utils/icons";
import VisibleIcon from '@rsuite/icons/Visible';
import CollapsedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import chatgpt_icon from '../../../../assets/chatgpt-icon.png';
import claude_icon from '../../../../assets/claude-ai-icon.png';
import gemini_icon from '../../../../assets/google-gemini-icon.png';
import SaveIcon from '@rsuite/icons/Save';
import useStandardToaster from "../../../ui_reusable/StandardToaster";
import ShowDataset from "../../../datasets/Show";

const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';

const ConfigProjectSolution = () => {
    const location = useLocation();
    const [projectSolution, setProjectSolution] = useState();
    const {id, solutionId} = location.state || {};
    const [isInline] = useMediaQuery('sm');
    const [LLM, setLLM] = useState('');
    const [personality, setPersonality] = useState('');
    const [strategicContext, setStrategicContext] = useState("");
    const [projectContext, setProjectContext] = useState("");
    const [companyContext, setCompanyContext] = useState("");
    const [industryContext, setIndustryContext] = useState("");
    const [competitiveContext, setCompetitiveContext] = useState("");
    const [timeframe, setTimeframe] = useState({
        from: new Date(),
        to: new Date(),
    });
    const [datasets, setDatasets] = useState([]);
    const [projectSolutionDrivers, setProjectSolutionDrivers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getProjectSolution = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProjectSolution(data);
            if(data.ai_settings){
                setLLM(data.ai_settings.llm);
                setPersonality(data.ai_settings.personality);
                setStrategicContext(data.ai_settings.strategic_context);
                setProjectContext(data.ai_settings.project_context);
                setCompanyContext(data.ai_settings.company_context);
                setIndustryContext(data.ai_settings.industry_context);
                setCompetitiveContext(data.ai_settings.competitive_context);
                setTimeframe(data.timeframe);
            }
        }
        const getProjectSolutionDrivers = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}/drivers?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjectSolutionDrivers(res.data);
        }
        const getDatasets = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setDatasets(res.data);
        }
        getProjectSolution();
        getProjectSolutionDrivers();
        getDatasets();
    },[id, solutionId, refresh]);

    const handleToggleSelect = async(value) => {
        let drivers = [];
        if(value === "all"){
            if(!selectAll){
                setSelectAll(true);
                drivers = datasets.map(elem=>{return {'driver_id': elem.id}});
            } else {
                setSelectAll(false);
                drivers = [];
            }
        } else {
            const selectedProjectSolution = projectSolutionDrivers.find(e=>e.driver.id===value)
            if(selectedProjectSolution){ //unchecked
                const updatedProjectSolutionDrivers = projectSolutionDrivers.filter(projectSolutionDriver=>
                    projectSolutionDriver.driver.id !== selectedProjectSolution.driver.id
                );
                const updatedSolutionDrivers = datasets.filter(dataset=>
                    updatedProjectSolutionDrivers.some(
                        (projectSolutionDriver) =>
                        projectSolutionDriver.driver.id === dataset.id
                    ));
                drivers = updatedSolutionDrivers.map(elem=>{return {'driver_id': elem.id}});
            } else {
                const selectedSolutionDriver = datasets.find(e=>e.id===value);
                const existingSolutionDrivers = datasets.filter(dataset=>
                    projectSolutionDrivers.some(
                        (projectSolutionDriver) =>
                        projectSolutionDriver.driver.id === dataset.id
                    ));
                drivers = [...existingSolutionDrivers.map(elem=>{return {'driver_id': elem.id}}), {'driver_id': selectedSolutionDriver.id}];
            }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body: JSON.stringify({drivers})
        });
        if (response.status === 200) {
            pushToast(<Message type="success">Solution drivers updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            setRefresh(new Date().getTime());
        } else {
            pushToast(<Message type="error">Failed to update solution drivers.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleUpdate = async()=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({timeframe})
        });
        if (response.status === 200) {
            pushToast(<Message type="success">Solution access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleUpdateAISettings = async() =>{
        const ai_settings = {
            llm: LLM,
            personality,
            strategic_context: strategicContext,
            project_context: projectContext,
            company_context: companyContext,
            industry_context: industryContext,
            competitive_context: competitiveContext,
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({ai_settings})
        });
        if (response.status === 200) {
            pushToast(<Message type="success">AI+ settings updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update AI+ settings.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const renderRowExpanded = (rowData) => {
        return (
            <ShowDataset datasetId={rowData.id} units={rowData.units}/>
        );
    };

    const handleExpanded = (rowData) => {
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });
    
        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
    
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    return (
        <Container style={{ margin: "20px 0"}}>
            {
                projectSolution && <>
                    <Panel>
                        <FlexboxGrid justify="space-between" style={{ marginBottom: "20px"}}>
                            <FlexboxGrid.Item colspan={6}><SectionTitle>Configure {projectSolution && projectSolution.solution.name}</SectionTitle></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>{(projectSolution.project.status==='active')?<Tag color="green">Project is active</Tag>:<Tag color="red">Project is inactive</Tag>}</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={id} size="lg" data={[{key: 1, label: projectSolution.project.name, value: projectSolution.project.id}]} readOnly/></FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>{projectSolution.solution.name} settings</h5>} collapsible bordered defaultExpanded>
                        <HStack style={{ margin: "0 20px" }}>
                            <Text weight="bold">Timeframe:</Text>
                            <DateRangePicker value={[new Date(timeframe.from), new Date(timeframe.to)]} onChange={(value)=>setTimeframe({from: value[0], to: value[1]}) }/>
                        </HStack>
                        <div style={{ textAlign: 'right' }}>
                            <Button appearance="primary" color="violet" startIcon={<SaveIcon/>} onClick={handleUpdate}>Save</Button>
                        </div>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Select drivers</h5>} collapsible bordered defaultExpanded>
                        <Panel bordered>
                            <FlexboxGrid justify="space-between" style={{ margin: "20px 0"}}>
                                <FlexboxGrid.Item colspan={4}><b>Available drivers:</b> {datasets.length}</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}><TagDefault label={`${projectSolutionDrivers.length} drivers selected`} textTransform="none"/></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}><InputGroup><InputGroup.Button><SearchIcon /></InputGroup.Button><Input placeholder="Find driver"/></InputGroup></FlexboxGrid.Item>
                            </FlexboxGrid>
                            <Table 
                                data={datasets} 
                                height={400} 
                                autoHeight 
                                rowHeight={60}
                                rowKey={rowKey}
                                expandedRowKeys={expandedRowKeys}
                                renderRowExpanded={renderRowExpanded}
                                rowExpandedHeight={650}
                                onRowClick={() => {
                                    // console.log(data);
                                }}
                            >
                                <Column flexGrow={2}>
                                    <HeaderCell><Checkbox color="violet" checked={selectAll} onChange={()=>{handleToggleSelect("all");}}>Select all/none</Checkbox></HeaderCell>
                                    <Cell>{rowData=><Checkbox color="violet" checked={!!projectSolutionDrivers.find(e=>e.driver.id===rowData.id)} onChange={()=>handleToggleSelect(rowData.id)}>{rowData.name}</Checkbox>}</Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell>Source</HeaderCell>
                                    <Cell>{rowData=>rowData.source}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Required driver</HeaderCell>
                                    <Cell>{rowData=><Checkbox color="violet" checked={projectSolutionDrivers.find(e => e.driver.id === rowData.id)?.is_required}></Checkbox>}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Last Update</HeaderCell>
                                    <Cell>{rowData=>new Date(rowData.updated_at).toLocaleDateString()}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Observations</HeaderCell>
                                    <Cell>{rowData=>rowData.observations}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Quality check</HeaderCell>
                                    <Cell>{rowData=>{
                                        if(rowData.quality==='good'){
                                            return (<IconSuccess/>)
                                        }
                                        else if(rowData.quality==='check'){
                                            return (<IconWarning/>)
                                        }
                                        else if(rowData.quality==='poor'){
                                            return (<IconDanger/>)
                                        }
                                    }}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>View</HeaderCell>
                                    <Cell>
                                        {
                                            rowData=>
                                                <IconButton
                                                appearance="subtle"
                                                onClick={() => {
                                                    handleExpanded(rowData);
                                                }}
                                                icon={
                                                expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                                                    <CollapsedOutlineIcon />
                                                ) : (
                                                    <VisibleIcon />
                                                )
                                                }
                                            />
                                        }</Cell>
                                </Column>
                            </Table>
                        </Panel>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Time series data prep</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Scenario settings</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Custom report settings</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>AI+ settings</h5>} collapsible bordered defaultExpanded>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginBottom: "5px"}}>
                                <Text weight="bold">Select LLM</Text>
                            </div>
                            <RadioTileGroup defaultValue={LLM} inline={isInline}>
                                <RadioTile value="chatgpt_4" label="ChatGPT 4.0" icon={<img src={chatgpt_icon} alt="chatgpt icon"/>} onClick={()=>setLLM("chatgpt_4")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                                <RadioTile value="claude" label="Claude" icon={<img src={claude_icon} alt="claude icon"/>} onClick={()=>setLLM("claude")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                                <RadioTile value="gemini" label="Gemini" icon={<img src={gemini_icon} alt="gemini icon"/>} onClick={()=>setLLM("gemini")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                            </RadioTileGroup>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Select personality</Text>
                            </div>
                            <RadioTileGroup defaultValue={personality} inline={isInline}>
                                <RadioTile value="brief" label="Brief and to-the-point" onClick={()=>setPersonality("brief")} style={{ width: "33%"}}>
                                    Professional and efficient. Prefers bullet points and easy-to-digest summaries.
                                </RadioTile>
                                <RadioTile value="analytical" label="Analytical and comprehensive" onClick={()=>setPersonality("analytical")} style={{ width: "33%"}}>
                                    Prefers full paragraphs. Emphasizes reasoning and explanations.
                                </RadioTile>
                                <RadioTile value="entrepreneurial" label="Analytical and entrepreneurial" onClick={()=>setPersonality("entrepreneurial")} style={{ width: "33%"}}>
                                    Combines full paragraphs and bullet points. Emphasizes trends, opportunities and ideas.
                                </RadioTile>
                            </RadioTileGroup>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Strategic context: What do you want to achieve and how?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={strategicContext||""} onChange={(value)=>setStrategicContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Project context: Tell us</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={projectContext||""} onChange={(value)=>setProjectContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Company context: Tell us</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={companyContext||""} onChange={(value)=>setCompanyContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Industry context: What are the key features, drivers and trends of the industry?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={industryContext||""} onChange={(value)=>setIndustryContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Competitive context: Who and what do you compete against, and what is the current market situation?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={competitiveContext||""} onChange={(value)=>setCompetitiveContext(value)}/>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button appearance="primary" color="violet" onClick={handleUpdateAISettings}>Save settings</Button>
                        </div>
                    </Panel>
                </>
            }
        </Container>
    );
}

export default ConfigProjectSolution;