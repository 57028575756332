import Plot from 'react-plotly.js';
import React, { useEffect, useState } from 'react';
import ZoominIcon from '@rsuite/icons/Zoomin';
import { IconButton, Modal, Panel, Text } from 'rsuite';
import {Loading} from '../../utils/loading';
import {capitalize} from '../../utils/helper';

const Analyze = ({datasetId, action}) => {
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState(null);
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    useEffect(()=>{
        if(datasetId && action){
            setLoading(true);
            const getAnalyze = async()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/${datasetId}/analyze?action=${action}`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response.status === 200){
                    const result = await response.json();
                    const res = result.data;
                    setData(res.data);
                    setLayout(res.layout);
                    setConfig(res.config);
                } else {
                    setData([]);
                    setLayout(null);
                    setConfig(null);
                }
                setLoading(false);
            }
            getAnalyze();
        }
    },[datasetId, action]);
    
    return (
        <Panel style={{ width: "100%" }} header={<><Text>{capitalize(action)}</Text><div style={{ textAlign: 'right'}}><IconButton appearance='default' size='sm' onClick={handleShow} icon={<ZoominIcon/>}/></div></>} bordered>
            {
                loading && <Loading width={200} height={200}/>
            }
            {
                (!loading && data && layout && config) && <Plot config={config} data={data} layout={layout} style={{ width: "100%"}}/>
            }
            <Modal open={show} onClose={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{capitalize(action)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                    (!loading && data && layout && config) &&  <Plot config={config} data={data} layout={layout} style={{ width: "100%"}}/>
                }
                </Modal.Body>
            </Modal>
        </Panel>
    );
}

export default Analyze;