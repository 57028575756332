
import styled from '@emotion/styled';
import { baseAccordionContainer, baseAccordionHeader } from '../../styles/PanelStyle';
import theme from '../../../styles/theme';

export const AccordionContainer = styled.div`
  ${baseAccordionContainer}
  gap: 20px;
`;

export const PanelContainer = styled.div`
  background-color: ${theme.colors.primaryVeryLight};
  box-shadow: ${theme.shadows.light};
  border-radius: 1.1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const DynamicHeader = styled.div`
  ${baseAccordionHeader}
  background-color: transparent;
  padding: 1rem;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    border-bottom-left-radius: ${props => props.isActive ? '0' : '1.5rem'};
    border-bottom-right-radius: ${props => props.isActive ? '0' : '1.5rem'};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => props.isActive ? 'rotate(45deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${theme.colors.primary};
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
`;

export const PanelContent = styled.div`
  padding: 1rem;
  overflow: hidden;
  will-change: height, opacity;
`;