/**
 * Debug Visualization System
 * 
 * Provides real-time visual debugging tools for React applications:
 * 1. Hold 'D' key to activate component visualization mode
 * 2. Hover over elements to:
 *    - Highlight all instances of components with the same class
 *    - Display source path information in a tooltip
 *    - Show Emotion styling class inheritance
 * 3. Click paths in tooltip to open files in VS Code
 * 
 * Features:
 * - Unique color assignment for each component class
 * - Interactive hover highlighting with smooth transitions
 * - Smart tooltip positioning with connection lines
 * - VS Code integration via openvscode:// protocol
 * - Emotion CSS class hierarchy visualization
 */

// Configuration constants for color generation and visual effects
const COLOR_MUTATION_DISTANCE = 80;  // Color difference between parent/child
const HOVER_MUTATION_DISTANCE = 40;  // Color shift on hover
const TOOLTIP_OFFSET_X = 10; // Tooltip positioning offset
const TOOLTIP_OFFSET_Y = -TOOLTIP_OFFSET_X;  // Tooltip positioning offset
const FORCE_FIELD_RADIUS = TOOLTIP_OFFSET_X*4; // Radius in pixels for the invisible force field


// Add project root constant at the top with other constants
const PROJECT_ROOT = 'C:/Users/tinde/Documents/Capia/citrus/citrus_ui';

// Update the helper function to properly escape backslashes
const createVSCodeUrl = (relativePath) => {
    // First, normalize the path with forward slashes
    const normalizedPath = `${PROJECT_ROOT}/${relativePath}`.replace(/\//g, '\\');
    // Then double-escape the backslashes for the URL
    const escapedPath = normalizedPath.replace(/\\/g, '\\\\');
    return `openvscode://?file=${escapedPath}`;
};

// Global state management
const colorMap = new Map();  // Stores base colors
const hoverColorMap = new Map();  // Stores hover-state colors
const processedClasses = new Set();
let activeHoverClass = null;
let debugSheet = null;

// Additional state management for original colors
const originalColorMap = new Map();  // Stores original background colors
let isDebugMode = false;

let tooltip;
let activeElement = null; // Add this to track the currently active element

// Add after other global variables
let activeLines = new Set();

const createTooltip = () => {
    tooltip = document.createElement('div');
    tooltip.style.position = 'fixed';
    tooltip.style.padding = '12px 16px';
    tooltip.style.background = 'rgba(33, 33, 33, 0.95)';
    tooltip.style.color = '#FFFFFF';
    tooltip.style.fontSize = '13px';
    tooltip.style.fontFamily = 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace';
    tooltip.style.lineHeight = '1.4';
    tooltip.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
    tooltip.style.backdropFilter = 'blur(4px)';
    tooltip.style.outline = '3px solid rgba(238, 255, 0, 1)';
    tooltip.style.zIndex = '1000';
    tooltip.style.cursor = 'text';
    tooltip.style.userSelect = 'text';
    tooltip.style.pointerEvents = 'auto';
    tooltip.style.transition = 'opacity 0.15s ease-in-out';
    tooltip.style.display = 'none'; // Ensure tooltip is hidden initially
    
    // Add hover handlers for tooltip
    tooltip.addEventListener('mouseenter', () => {
        tooltip.dataset.isHovered = 'true';
    });
    
    tooltip.addEventListener('mouseleave', () => {
        tooltip.dataset.isHovered = 'false';
        // Only hide if we're not back on the original element
        if (!activeElement?.matches(':hover')) {
            hideTooltip();
            activeElement = null;
        }
    });

    document.body.appendChild(tooltip);
};



const updateTooltipPosition = (element) => {
    const elementRect = element.getBoundingClientRect();
    
    // First position the tooltip to make it visible (needed for height calculation)
    tooltip.style.display = 'block';
    tooltip.style.left = `${elementRect.right + TOOLTIP_OFFSET_X}px`;
    tooltip.style.top = `${elementRect.top}px`;
    
    // Now we can get the actual dimensions after the tooltip is visible and has content
    const tooltipHeight = tooltip.offsetHeight;
    const tooltipWidth = tooltip.offsetWidth;
    
    // Calculate final positions with offset
    const finalTop = elementRect.top - tooltipHeight + TOOLTIP_OFFSET_Y;
    const finalLeft = elementRect.right + TOOLTIP_OFFSET_X;
    
    // Position tooltip
    tooltip.style.top = `${finalTop}px`;
    tooltip.style.left = `${finalLeft}px`;

    // Clear any existing connection line
    clearAllLines();
    
    // Draw connection line from element's top-right to tooltip's bottom-left
    drawLine(
        elementRect.right,  // start x (element top-right)
        elementRect.top,    // start y (element top-right)
        finalLeft,          // end x (tooltip bottom-left)
        finalTop + tooltipHeight , // end y (tooltip bottom-left)
        'rgba(255, 255, 0, 1)', // semi-transparent white
        3  // thin line
    );
};

// Update parseEmotionClasses to handle multiple classes and better formatting
const parseEmotionClasses = (className) => {
    // Match multiple components separated by ---
    const components = className.split('---');
    return components
        .map(component => {
            // Match the folder/file structure and class name
            const match = component.match(/([^_]+)__([^_]+)__([^-]+)/);
            if (!match) return null;

            const [, folder, file, className] = match;
            return {
                folder: folder.replace(/-/g, '/'),
                file,
                className
            };
        })
        .filter(Boolean); // Remove any null results
};

const formatPath = (path) => {
    // Extract just the filename without extension
    const parts = path.split('/');
    const filename = parts[parts.length - 1];
    const directory = parts.slice(0, -1).join('/');
    
    return {
        path: directory,
        tag: filename
    };
};

// Update the tooltip content generation for emotion classes
const updateTooltipContent = (content) => {
    const parts = content.split('\n');
    let tooltipContent = parts.map(part => {
        const [label, fullPath] = part.split(': ');
        const { path, tag } = formatPath(fullPath);
        
        // Create VS Code URL for the file - removed extra .js since it's already included in the path
        const vsCodeUrl = createVSCodeUrl(`${fullPath}`);
        
        return `<div style="margin: 4px 0;">
            <span style="color: #888;">${label}:</span>
            <div style="margin-left: 12px;">
                <a href="${vsCodeUrl}" 
                   style="text-decoration: none; display: inline-block; cursor: pointer;"
                   onclick="event.preventDefault(); window.open('${vsCodeUrl}', '_blank');">
                    <span style="color: #63B3ED; font-family: monospace;">${path}/</span>
                    <span style="color: #F9E076; font-family: monospace;">${tag}</span>
                </a>
            </div>
        </div>`;
    }).join('');

    // Add Emotion classes if element has any
    if (activeElement) {
        const emotionClasses = Array.from(activeElement.classList)
            .filter(cls => cls.startsWith('css-'))
            .map(cls => {
                const classMatch = cls.match(/css-[a-z0-9]+-(.+)/);
                return classMatch ? classMatch[1] : null;
            })
            .filter(Boolean)
            .flatMap(cls => parseEmotionClasses(cls))
            .reverse(); // Reverse the array to show hierarchy from top to bottom

        if (emotionClasses.length > 0) {
            tooltipContent += `
                <div style="margin-top: 8px; padding-top: 8px; border-top: 1px solid rgba(255,255,255,0.1);">
                    <span style="color: #888;">Emotion classes:</span>
                    ${emotionClasses.map((cls, index) => `
                        <div style="margin: 4px 0 4px 12px; font-family: monospace;">
                            <div style="display: flex; align-items: center;">
                                <div>
                                    <span style="color: #63B3ED;">${cls.folder}/</span>
                                    <span style="color: #F9E076;">${cls.file}.js</span>
                                    <span style="color: #888;"> → </span>
                                    <span style="color: #4FD1C5;">${cls.className}</span>
                                </div>
                            </div>
                            ${index < emotionClasses.length - 1 ? `
                                <div style="
                                    margin-left: 20px;
                                    color: #666;
                                    font-size: 14px;
                                    line-height: 1.2;
                                    margin-top: 2px;
                                    margin-bottom: 2px;
                                ">↓</div>
                            ` : ''}
                        </div>
                    `).join('')}
                </div>
            `;
        }
    }
    
    tooltip.innerHTML = tooltipContent;
};

const showTooltip = () => {
    if (!isDebugMode) return; // Only show tooltip if debug mode is active
    tooltip.style.display = 'block';
    tooltip.style.opacity = '0';
    // Force reflow to enable transition (fix ESLint error)
    void tooltip.offsetHeight;  // Using void operator to satisfy ESLint
    tooltip.style.opacity = '1';
};

// Update hideTooltip to respect the hover states
const hideTooltip = () => {
    if (tooltip?.dataset.isHovered === 'true' || activeElement?.matches(':hover') || forceField?.dataset.isHovered === 'true') {
        return;
    }
    tooltip.style.opacity = '0';
    clearAllLines(); // Clear connection line when hiding tooltip
    if (forceField) {
        forceField.style.display = 'none';
    }
    setTimeout(() => {
        if (tooltip.style.opacity === '0') {
            tooltip.style.display = 'none';
        }
    }, 150);
};


// Color generation and manipulation functions
const generateRandomBaseColor = () => {
    return [
        Math.floor(Math.random() * 256),
        Math.floor(Math.random() * 256),
        Math.floor(Math.random() * 256)
    ];
};

const mutateColor = (parentColor) => {
    // Generate random direction in 3D space
    const theta = Math.random() * 2 * Math.PI;
    const phi = Math.random() * 2 * Math.PI;
    
    // Convert spherical coordinates to cartesian
    const dx = COLOR_MUTATION_DISTANCE * Math.sin(phi) * Math.cos(theta);
    const dy = COLOR_MUTATION_DISTANCE * Math.sin(phi) * Math.sin(theta);
    const dz = COLOR_MUTATION_DISTANCE * Math.cos(phi);
    
    // Apply mutation while keeping within bounds
    return parentColor.map((channel, i) => {
        const delta = [dx, dy, dz][i];
        return Math.min(255, Math.max(0, Math.round(channel + delta)));
    });
};

const mutateColorForHover = (baseColor) => {
    // Similar to mutateColor but uses fixed direction for consistency
    // Move diagonally in RGB space for a predictable hover effect
    return baseColor.map(channel => 
        Math.min(255, Math.max(0, channel + HOVER_MUTATION_DISTANCE))
    );
};

const rgbToString = (rgb) => `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;

// Helper function to store original background color
const storeOriginalColor = (element) => {
    const computedStyle = window.getComputedStyle(element);
    const originalColor = computedStyle.backgroundColor;
    element.classList.forEach(className => {
        if (!originalColorMap.has(className)) {
            originalColorMap.set(className, originalColor);
        }
    });
};

// Add new function for centralized highlight management
const clearAllHighlights = () => {
    document.querySelectorAll('.hover-active').forEach(el => {
        el.classList.remove('hover-active');
    });
};

const applyHighlight = (element) => {
    clearAllHighlights();
    if (!element) return;
    
    const elementId = ensureElementIdentifier(element);
    if (!elementId) return;
    
    if (elementId.startsWith(UNCLASSED_PREFIX)) {
        // For unclassed elements, only highlight the element itself
        element.classList.add('hover-active');
    } else {
        // For classed elements, continue with existing behavior
        document.querySelectorAll(`.${elementId}`).forEach(el => 
            el.classList.add('hover-active')
        );
    }
};

// Update setupHoverHighlight
const setupHoverHighlight = () => {
    const handleMouseOver = (event) => {
        if (!isDebugMode) return;
        const element = event.target;
        
        // Ignore if we're hovering the tooltip, force field, or line
        if (
            element === tooltip || 
            element === forceField ||
            tooltip?.dataset.isHovered === 'true' ||
            forceField?.dataset.isHovered === 'true'
        ) {
            return;
        }
        
        const elementId = ensureElementIdentifier(element);
        if (!elementId) return;

        const usedIn = element.getAttribute('data-folder-used');
        const definedIn = element.getAttribute('data-folder-defined');
        
        if (!usedIn && !definedIn) return;

        // Store the active element and apply highlight
        activeElement = element;
        applyHighlight(element);

        // Update tooltip
        let tooltipText = '';
        if (definedIn) tooltipText += `Defined in: ${definedIn}.js\n`;
        if (usedIn) tooltipText += `Used in: ${usedIn}`;
        if (elementId.startsWith(UNCLASSED_PREFIX)) {
            tooltipText += `\nUnnamed element: ${element.tagName.toLowerCase()}`;
        }

        updateTooltipContent(tooltipText.trim());
        updateTooltipPosition(element);
        showTooltip();
        updateForceFieldPosition(element);
    };

    const handleMouseOut = (event) => {
        if (!isDebugMode) return;
        const element = event.target;
        
        // Don't clear highlights if we're moving to the tooltip or force field
        if (
            event.relatedTarget === tooltip || 
            event.relatedTarget === forceField ||
            tooltip?.dataset.isHovered === 'true' ||
            forceField?.dataset.isHovered === 'true'
        ) {
            return;
        }

        // Only proceed if this is the active element
        if (element !== activeElement) {
            return;
        }

        hideTooltip();
        activeElement = null;
        clearAllHighlights();
    };

    // Update tooltip and force field mouse handlers to use new highlight management
    tooltip.addEventListener('mouseleave', () => {
        tooltip.dataset.isHovered = 'false';
        if (!activeElement?.matches(':hover') && !forceField?.dataset.isHovered === 'true') {
            hideTooltip();
            activeElement = null;
            clearAllHighlights();
        }
    });

    forceField.addEventListener('mouseleave', () => {
        forceField.dataset.isHovered = 'false';
        if (!tooltip?.dataset.isHovered === 'true' && !activeElement?.matches(':hover')) {
            hideTooltip();
            activeElement = null;
            clearAllHighlights();
        }
    });

    // Add hover listeners to document
    document.addEventListener('mouseover', handleMouseOver, true);
    //document.addEventListener('mousemove', event => {
    //    if (tooltip && tooltip.style.display === 'block') {
   //         //updateTooltipPosition(event.clientX, event.clientY);
    //    }
    //});
    document.addEventListener('mouseout', handleMouseOut, true);
};

// Enhanced element processing
const processElement = (element, debugSheet, processedClasses) => {
    const elementId = ensureElementIdentifier(element);
    
    // Skip if already processed
    if (processedClasses.has(elementId)) return;
    
    // Store original colors before applying debug colors
    storeOriginalColor(element);
    
    // Find parent's color if it exists
    let parentColor = null;
    let parent = element.parentElement;
    while (parent && !parentColor) {
        const parentId = ensureElementIdentifier(parent);
        if (colorMap.has(parentId)) {
            parentColor = colorMap.get(parentId);
            break;
        }
        parent = parent.parentElement;
    }
    
    // Generate colors for this element
    const baseColor = parentColor ? 
        mutateColor(parentColor) : 
        generateRandomBaseColor();
    const hoverColor = mutateColorForHover(baseColor);
    
    // Store both colors
    colorMap.set(elementId, baseColor);
    hoverColorMap.set(elementId, hoverColor);
    processedClasses.add(elementId);
    
    try {
        if (elementId.startsWith(UNCLASSED_PREFIX)) {
            // For elements without classes, use attribute selector
            debugSheet.insertRule(
                `[data-debug-id="${elementId}"].debug-mode { 
                    background-color: ${rgbToString(baseColor)} !important;
                }`,
                debugSheet.cssRules.length
            );
            
            debugSheet.insertRule(
                `[data-debug-id="${elementId}"].debug-mode.hover-active { 
                    background-color: ${rgbToString(hoverColor)} !important;
                    outline: 3px dashed yellow !important;
                    position: relative !important;
                    z-index: 1 !important;
                }`,
                debugSheet.cssRules.length
            );
        } else {
            // For elements with classes, use class selector
            // Clean the elementId to ensure it's a valid CSS selector
            const cleanId = elementId.replace(/[[\]]/g, '\\$&');

            debugSheet.insertRule(
                `.${cleanId}.debug-mode { 
                    background-color: ${rgbToString(baseColor)} !important; 
                }`,
                debugSheet.cssRules.length
            );

            debugSheet.insertRule(
                `.${cleanId}.debug-mode.hover-active { 
                    background-color: ${rgbToString(hoverColor)} !important;
                    outline: 3px dashed yellow !important;
                    position: relative !important;
                    z-index: 1 !important;
                }`,
                debugSheet.cssRules.length
            );
        }
    } catch (e) {
        console.warn('Failed to insert CSS rule for element:', elementId, e);
    }
};

const processStyleSheet = (sheet, processedClasses, debugSheet) => {
    try {
        if (sheet === debugSheet || !sheet.cssRules) return;

        Array.from(sheet.cssRules).forEach(rule => {
            if (rule.selectorText && rule.selectorText.includes('.')) {
                const matches = rule.selectorText.match(/\.[a-zA-Z0-9_-]+/g);
                if (matches) {
                    // Find elements matching these classes and process them
                    matches.forEach(selector => {
                        document.querySelectorAll(selector).forEach(element => {
                            processElement(element, debugSheet, processedClasses);
                        });
                    });
                }
            }
        });
    } catch (e) {
        console.debug('Skipping restricted stylesheet', e);
    }
};

const createDebugSheet = () => {
    const style = document.createElement('style');
    style.setAttribute('data-debug-colors', 'true');
    document.head.appendChild(style);
    return style.sheet;
};

const observeStyleChanges = (debugSheet, processedClasses) => {
    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
                if (node.nodeType === 1) {
                    if (node.tagName === 'STYLE' || node.tagName === 'LINK') {
                        if (node.sheet) {
                            processStyleSheet(node.sheet, processedClasses, debugSheet);
                        }
                    }
                    // Process the new element and all its children
                    processElement(node, debugSheet, processedClasses);
                    node.querySelectorAll('*').forEach(child => {
                        processElement(child, debugSheet, processedClasses);
                    });
                }
            });
        });
    });

    observer.observe(document.documentElement, {
        childList: true,
        subtree: true
    });

    return observer;
};

// Add keyboard toggle functionality
const setupKeyboardToggle = () => {
    const toggleDebugMode = (active) => {
        isDebugMode = active;
        document.querySelectorAll('*').forEach(element => {
            const elementId = element.getAttribute('data-debug-id');
            if (elementId || element.classList.length > 0) {
                if (active) {
                    element.classList.add('debug-mode');
                } else {
                    element.classList.remove('debug-mode');
                    element.classList.remove('hover-active');
                    // Restore original color
                    const originalColor = originalColorMap.get(elementId || element.classList[0]);
                    if (originalColor) {
                        element.style.backgroundColor = originalColor;
                    }
                }
            }
        });

        // Add this line to hide tooltip when debug mode is turned off
        if (!active) {
            hideTooltip();
            clearAllLines(); // Add this line
            if (forceField) {
                forceField.style.display = 'none';
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key && event.key.toLowerCase() === 'd' && !isDebugMode) {
            toggleDebugMode(true);
        }
    };
    const handleKeyUp = (event) => {
        if (event.key && event.key.toLowerCase() === 'd') {
            toggleDebugMode(false);
        }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
    };
};

// Enhanced initialization
export const initDebugColors = () => {
    if (process.env.REACT_APP_DEBUG_COLORS !== 'true') return;

    const init = () => {
        if (!tooltip) createTooltip(); 
        debugSheet = createDebugSheet();

        // Process existing elements and store original colors
        document.querySelectorAll('[class]').forEach(element => {
            storeOriginalColor(element);
        });

        // Initialize base colors
        Array.from(document.styleSheets).forEach(sheet => {
            processStyleSheet(sheet, processedClasses, debugSheet);
        });

        // Setup hover highlighting and store cleanup function
        const cleanupHoverHighlight = setupHoverHighlight();
        const cleanupKeyboardToggle = setupKeyboardToggle();

        // Start observing DOM changes
        const observer = observeStyleChanges(debugSheet, processedClasses);

        // Return cleanup function for potential future use
        return () => {
            cleanupHoverHighlight();
            cleanupKeyboardToggle();
            observer.disconnect();
        };
    };

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', init);
    } else {
        init();
    }
};

const drawLine = (x0, y0, x1, y1, color = '#FF0000', thickness = 2) => {
    // Calculate length and angle
    const length = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
    const angle = Math.atan2(y1 - y0, x1 - x0) * 180 / Math.PI;
    
    // Create line element
    const line = document.createElement('div');
    line.style.position = 'fixed';
    line.style.width = `${length}px`;
    line.style.height = `${thickness}px`;
    line.style.backgroundColor = color;
    line.style.zIndex = '9999';
    line.style.pointerEvents = 'none'; // Make line non-interactive
    line.style.transformOrigin = '0 50%';
    
    // Position the line at starting point and rotate
    line.style.left = `${x0}px`;
    line.style.top = `${y0 - thickness/2}px`;
    line.style.transform = `rotate(${angle}deg)`;
    
    // Add line to document
    document.body.appendChild(line);
    activeLines.add(line);
    
    // Return cleanup function
    return () => {
        if (document.body.contains(line)) {
            document.body.removeChild(line);
            activeLines.delete(line);
        }
    };
};

// Add cleanup function for lines
const clearAllLines = () => {
    activeLines.forEach(line => {
        if (document.body.contains(line)) {
            document.body.removeChild(line);
        }
    });
    activeLines.clear();
};


const createForceField = () => {
    const field = document.createElement('div');
    field.style.position = 'fixed';
    field.style.width = `${FORCE_FIELD_RADIUS * 2}px`;
    field.style.height = `${FORCE_FIELD_RADIUS * 2}px`;
    field.style.borderRadius = '50%';
    field.style.pointerEvents = 'auto';
    field.style.zIndex = '999';
    // Uncomment for debugging:
    // field.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
    
    // Add hover handlers for force field
    field.addEventListener('mouseenter', () => {
        field.dataset.isHovered = 'true';
    });
    
    field.addEventListener('mouseleave', () => {
        field.dataset.isHovered = 'false';
        if (!tooltip?.dataset.isHovered === 'true') {
            hideTooltip();
            activeElement = null;
            clearAllHighlights();
        }
    });

    document.body.appendChild(field);
    return field;
};

// Add force field to global state
let forceField = createForceField(); // Ensure force field is created initially

const updateForceFieldPosition = (element) => {
    const elementRect = element.getBoundingClientRect();
    // Position force field centered on the top-right corner
    forceField.style.left = `${elementRect.right - FORCE_FIELD_RADIUS}px`;
    forceField.style.top = `${elementRect.top - FORCE_FIELD_RADIUS}px`;
    forceField.style.display = 'block';
};

// Add after other constants
let uniqueIdCounter = 0;
const UNCLASSED_PREFIX = 'debug-unclassed-';

// Add this helper function after other helpers
const ensureElementIdentifier = (element) => {
    // If element has classes, return the first class
    if (element.classList.length > 0) {
        return element.classList[0];
    }
    
    // Check if we already assigned an ID
    let id = element.getAttribute('data-debug-id');
    if (!id) {
        // Generate new unique ID
        id = `${UNCLASSED_PREFIX}${uniqueIdCounter++}`;
        element.setAttribute('data-debug-id', id);
    }
    return id;
};