
import styled from '@emotion/styled';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';

export const Button = styled.button`
    font-family: Inter, sans-serif;
    line-height: 1rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #344054;
    letter-spacing: .02rem;
    padding: 0.4rem 0.5rem 0.35rem 0.9rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: box-shadow 0.3s ease;

    &:hover {
        background-color: rgba(0,0,0,0.03);
    }

    &.open {
        box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.06);
    }
`;

export const StyledArrowDownLineIcon = styled(ArrowDownLineIcon)`
    font-size: 1.2rem;
    position: relative;
    top: -0.13rem;
`;

export const StyledArrowUpLineIcon = styled(ArrowUpLineIcon)`
    font-size: 1.2rem;
    position: relative;
    top: -0.2rem;
`;