import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './auth/Login';
import {AuthProvider, useAuth} from './context/AuthContext';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './auth/Logout';
import ModelList from './components/model/List';
import ModelRun from './components/model/Run';
import Profile from './components/Profile';
import ProjectList from './components/projects/List';
import CreateProject from './components/projects/Create';
import ShowProject from './components/projects/Show';
import ClientList from './components/clients/List';
import CreateClient from './components/clients/Create';
import ConfigClient from './components/clients/managements/Configure';
import ConfigTeam from './components/teams/Configure';
import ModelDashboard from './components/model/Dashboard';
import DatasetList from './components/datasets/List';
import CreateDataset from './components/datasets/Create';
import ManageFAQ from './components/learning/faq/Manage';
import Learning from './components/learning/Dashboard';
import ListFAQ from './components/learning/faq/List';
import ManageIntro from './components/learning/intro/Manage';
import Intro from './components/learning/intro/Show';
import ManageAcademy from './components/learning/academy/Manage';
import Academy from './components/learning/academy/Show';
import ModelBuild from './components/model/Build';
import Report from './components/model/Report';
import Dashboard from './components/Dashboard';
import { DocumentScreenBehaviour,DocumentBelowNavbarBehaviour } from './styles/GlobalStyles';
import ConfigProjectSolution from './components/projects/managements/solutions/Configure';
import { useEffect } from 'react';

import theme from './styles/theme';
import { ThemeProvider } from '@emotion/react';
import AcceptInvitation from './components/invitations/Accept';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ThemeProvider>
  );
}

function AppContent() {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      // Fetch user info or trigger any state updates needed after login
    }
  }, [isAuthenticated]);

  const isInviteRoute = window.location.pathname.startsWith('/invite/');

  return (
    <Router>
      <div css={DocumentScreenBehaviour}>
        {!isInviteRoute && (
            <Navigation />
        )}

        <div css={DocumentBelowNavbarBehaviour}>
          <Routes>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/invite/:token' element={<AcceptInvitation />} />
            <Route path='/models/new' element={<ProtectedRoute><ModelDashboard /></ProtectedRoute>} />
            <Route path='/models/build' element={<ProtectedRoute><ModelBuild /></ProtectedRoute>} />

            <Route path='/models' element={<ProtectedRoute><ModelList /></ProtectedRoute>} />
            <Route path='/models/:name' element={<ProtectedRoute><ModelRun /></ProtectedRoute>} />
            <Route path='/report' element={<ProtectedRoute><Report /></ProtectedRoute>} />
            <Route path='/datasets/create' element={<ProtectedRoute><CreateDataset /></ProtectedRoute>} />
            <Route path='/datasets' element={<ProtectedRoute><DatasetList /></ProtectedRoute>} />
            <Route path='/projects/create' element={<ProtectedRoute><CreateProject /></ProtectedRoute>} />
            <Route path='/projects' element={<ProtectedRoute><ProjectList /></ProtectedRoute>} />
            <Route path='/projects/:project' element={<ProtectedRoute><ShowProject /></ProtectedRoute>} />
            <Route path='/projects/:project/solutions/:solution' element={<ProtectedRoute><ConfigProjectSolution /></ProtectedRoute>} />

            <Route path='/clients/create' element={<ProtectedRoute><CreateClient /></ProtectedRoute>} />
            <Route path='/clients' element={<ProtectedRoute><ClientList /></ProtectedRoute>} />
            <Route path='/clients/:client' element={<ProtectedRoute><ConfigClient /></ProtectedRoute>} />

            <Route path='/teams' element={<ProtectedRoute><ConfigTeam /></ProtectedRoute>} />
            <Route path='/learning' element={<ProtectedRoute><Learning /></ProtectedRoute>} />
            <Route path='/learning/faq' element={<ProtectedRoute><ManageFAQ /></ProtectedRoute>} />
            <Route path='/faq' element={<ProtectedRoute><ListFAQ /></ProtectedRoute>} />
            <Route path='/learning/intro' element={<ProtectedRoute><ManageIntro /></ProtectedRoute>} />
            <Route path='/intro' element={<ProtectedRoute><Intro /></ProtectedRoute>} />
            <Route path='/learning/academy' element={<ProtectedRoute><ManageAcademy /></ProtectedRoute>} />
            <Route path='/academy' element={<ProtectedRoute><Academy /></ProtectedRoute>} />
            <Route path='/login' element={<Login />} />
            <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path='/logout' element={<Logout />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
