import React, { useState, useEffect, createContext, useContext, useCallback, forwardRef } from 'react';
import { standardRadioStyle } from './styles/StandardRadioButtonsStyles';

// Create Radio Context
const RadioContext = createContext();

// Radio Provider Component
export const RadioProvider = ({ children }) => {
  const [selectedValues, setSelectedValues] = useState({});

  const setGroupValue = useCallback((group, value) => {
    setSelectedValues(prev => ({
      ...prev,
      [group]: value
    })); 
  }, []);

  return (
    <RadioContext.Provider value={{ selectedValues, setGroupValue }}>
      {children}
    </RadioContext.Provider>
  );
};

// Enhanced Standard Radio Component
export const StandardRadio = forwardRef(({ onChange, initState = false, group, value, size = 'l' }, ref) => {
  const context = useContext(RadioContext);

  // Initialize provider state if initState is true (only on mount)
  useEffect(() => {
    if (group && context && initState) {
      context.setGroupValue(group, value);
    }
  }, []); // Empty dependency array since this should only run once

  // Get current selection state from context or fallback to local state
  const isSelected = group && context 
    ? context.selectedValues[group] === value 
    : initState;

  const handleChange = (e) => {
    if (group && context) {
      context.setGroupValue(group, value);
    }

    if (onChange) onChange(true);
  };

  return (
    <input
      ref={ref}
      type="radio"
      checked={isSelected}
      onChange={handleChange}
      css={standardRadioStyle(size)}
    />
  );
});
