import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {InfoSmall500Style,InfoExtraSmall600Style} from '../../styles/TextStyles';
import theme from '../../../styles/theme';
/*
* Base tag component that provides common styling for all tags
*/
const BaseTag = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 0.3rem 0.5rem;
    border-radius: 4rem;
    display: inline-block;
`;

/*
* Active tag component with default green styling
*/
const StyledSuccessTag = styled(BaseTag)`
    color: ${props => props.color || "#12b76a"};
    background-color: ${props => props.bg || "#ecfdf3"};
    text-transform: capitalize;
`;

/*
* Inactive tag component with default orange styling
*/
const StyledWarningTag = styled(BaseTag)`
    color: ${props => props.color || "#f79009"};
    background-color: ${props => props.bg || "#fffaeb"};
    text-transform: capitalize;
`;

const StyledDangerTag = styled(BaseTag)`
    color: ${props => props.color || "#f70909"};
    background-color: ${props => props.bg || "#ffebeb"};
    text-transform: capitalize;
`;


/*
* Normal tag component with default purple styling
*/
const StyledNormalTag = styled(BaseTag)`
    color: ${theme.colors.primary};
    background-color: ${theme.colors.primaryLight};
    font-size: 0.8rem;
    font-weight: 600;
`;

/*
* Filled active tag component with solid green background
*/
const StyledFilledActiveTag = styled(BaseTag)`
    color: white;
    background-color: ${props => props.color || "#12b76a"};
    text-transform: capitalize;
`;

/*
* Filled inactive tag component with solid orange background
*/
const StyledFilledInactiveTag = styled(BaseTag)`
    color: white;
    background-color: ${props => props.color || "#f79009"};
    text-transform: capitalize;
`;

/*
* Small tag component for minimal information display
*/
export const SmallNormalTag = styled.span`
    ${InfoSmall500Style};
    background-color: ${theme.colors.primaryVeryLight};
    color: ${theme.colors.primary}  !important  ;
    padding: 0.4rem 0.5rem!important  ;
    border-radius: 4rem;
    display: inline-block;
    margin-right: 0.5rem;
`;


export const VerySmallNormalTag = styled.span`
    ${InfoExtraSmall600Style};
    background-color: ${theme.colors.primaryLight } !important  ;
    color: ${theme.colors.primary}  !important  ;
    padding: 0.4rem 0.4rem!important  ;
    border-radius: 0.6rem;
    display: inline-block;
    margin-right: 0.5rem;
`;


// Exported components with their respective props
export const TagSuccess = ({ label, color, bg }) => (
    <StyledSuccessTag color={color} bg={bg}>
        <FontAwesomeIcon icon={faCircle} size="xs"/> {label}
    </StyledSuccessTag>
);

export const TagWarning = ({ label, color, bg }) => (
    <StyledWarningTag color={color} bg={bg}>
        <FontAwesomeIcon icon={faCircle} size="xs"/> {label}
    </StyledWarningTag>
);

export const TagDanger = ({ label, color, bg }) => (
    <StyledDangerTag color={color} bg={bg}>
        <FontAwesomeIcon icon={faCircle} size="xs"/> {label}
    </StyledDangerTag>
);

export const TagFilledActive = ({ label, color }) => (
    <StyledFilledActiveTag color={color}>
        <FontAwesomeIcon icon={faCircle} size="xs"/> {label}
    </StyledFilledActiveTag>
);

export const TagFilledInactive = ({ label, color }) => (
    <StyledFilledInactiveTag color={color}>
        <FontAwesomeIcon icon={faCircle} size="xs"/> {label}
    </StyledFilledInactiveTag>
);

