import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { InfoXXXSmall500Style, TextMediumMini400Style } from '../../styles/TextStyles';


export const CellContentStyle = css`
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: clamp(0.5rem,2%,4rem);
  padding-left: clamp(0.5rem,2%,4rem);
  overflow: hidden;
`;

export const CellStyle = (props) =>  css`
    width: 100%;
    min-height:2.5rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    padding: 0.5rem;

    & > * {
      
      
      ${props.center && `
          margin-left: auto;
      `}
      margin-right: auto;
    }

    &:nth-of-type(${props.columns}n+1) {
      & > * {
        margin-left: 0;
        margin-right: auto;
      }
    }
`;


export const CellKeeperStyle = (props) =>  css`
    width: 100%;
    min-height: 3rem;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${!props.isLastRow && `
      border-bottom: 1px solid ${props.theme.colors.backgroundDark};
    `}

    ${props.borderLeft && `
      border-left: 1px solid ${props.theme.colors.backgroundDark};
    `}
    
`;