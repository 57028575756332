import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

// Speed up animations by reducing duration
const circleAnimation = keyframes`
  0% { stroke-dashoffset: 285; }
  100% { stroke-dashoffset: 0; }
`;

const checkAnimation = keyframes`
  0% { stroke-dashoffset: 80; }
  100% { stroke-dashoffset: 0; }
`;

const textFadeIn = keyframes`
  0% { opacity: 0; transform: translateX(-10px); }
  100% { opacity: 1; transform: translateX(0); }
`;

// Modified container to include text
const Container = styled.div`
  height: 80px;  // Add fixed height
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const SVGWrapper = styled.svg`
  height: 50px;  // Fixed height instead of percentage
  width: 50px;   // Fixed width to maintain aspect ratio
  transform: scale(0.8);
`;

const Circle = styled.circle`
  fill: none;
  stroke: #7ac142;
  stroke-width: 4;
  stroke-dasharray: 285;
  stroke-dashoffset: 285;
  animation: ${circleAnimation} 0.45s ease-in-out forwards;
  animation-fill-mode: forwards;
  animation-play-state: paused;
  ${props => props.show && `
    animation-play-state: running;
  `}
`;

const Check = styled.path`
  fill: none;
  stroke: #7ac142;
  stroke-width: 4;
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: ${checkAnimation} 0.25s ease-in-out forwards;
  animation-delay: 0.475s;
  animation-fill-mode: forwards;
  animation-play-state: paused;
  ${props => props.show && `
    animation-play-state: running;
  `}
`;

const Text = styled.div`
  font-size: 1.2rem;
  color: #7ac142;
  opacity: 0;
  animation: ${textFadeIn} 0.3s ease-out forwards;
  animation-delay: 0.725s;
  animation-fill-mode: forwards;
  animation-play-state: paused;
  ${props => props.show && `
    animation-play-state: running;
  `}
`;

// Main wrapper
const CheckmarkAnimation = styled.div`
  height: 100%;
  width: 100%;
  
  ${Container} {
    opacity: 1;  // Changed from conditional opacity
    visibility: ${props => props.show ? 'visible' : 'hidden'};
  }
`;

export default CheckmarkAnimation;
export { Container, SVGWrapper, Circle, Check, Text };
