import { useEffect, useState, useCallback, useRef } from "react";
import { Message, SelectPicker } from "rsuite";
import { useForm } from "react-hook-form";

import ImageUploader from '../ui_reusable/ImageUploader';
import { StandardButton } from "../ui_reusable/styled_comps/Buttons";
import useStandardToaster from '../ui_reusable/StandardToaster';
import { ButtonContainer, Label, FormGroup, FormSection, GridLayout, Container, SuccessContainer } from '../ui_reusable/styled_comps/CreateInputUtils';
import { StandardInput } from "../ui_reusable/StandardInputs";
import CreatorAccordion from "../ui_reusable/CreatorAccordion";
import CheckmarkAnimation from '../ui_reusable/CheckmarkAnimation';
import { ErrorMessage, ServerErrorMessage } from '../ui_reusable/styled_comps/CreateInputUtils';

const CreateProject = ({ setRefresh, setShowCreateProject, clientId }) => {
    const [kams, setKams] = useState([]);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileList, setFileList] = useState([]);
    const { pushToast } = useStandardToaster();
    const [uploading, setUploading] = useState(false);
    const panelRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [removeContainer, setRemoveContainer] = useState(true);
    const [serverError, setServerError] = useState(null);

    const { 
        register, 
        handleSubmit, 
        setValue,
        watch,
        formState: { errors },
        reset,
        clearErrors,
        setError
    } = useForm({
        defaultValues: {
            name: "",
            project_id: "",
            client_id: clientId || "", // Use provided clientId if available
            kam_id: ""
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    // Fetch KAMs and Clients - Fixed infinite loop by removing pushToast from deps
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [kamsResponse, clientsResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/api/users/kams/`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json'
                        }
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/clients/`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json'
                        }
                    })
                ]);

                const [kamsData, clientsData] = await Promise.all([
                    kamsResponse.json(),
                    clientsResponse.json()
                ]);

                setKams(kamsData.data || []);
                setClients(clientsData.data || []);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                pushToast(
                    <Message type="error">Failed to load required data</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []); // Removed pushToast from dependency array

    // Handle image preview cleanup
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    // Image handling functions - Copied from clients/Create.js
    const handleFileChange = useCallback((files) => {
        if (!files || files.length === 0) return;
        setUploading(true);
        const file = files[files.length - 1];
        setUploading(false);
        
        if (!file.blobFile.type.startsWith('image/')) {
            pushToast(
                <Message type="error">Please upload an image file</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            return;
        }

        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        
        const objectUrl = URL.createObjectURL(file.blobFile);
        setPreviewUrl(objectUrl);
        setFileList([file]);
        pushToast(
            <Message type="success">Image selected successfully</Message>,
            { placement: 'topCenter', duration: 5000 }
        );
    }, [previewUrl, pushToast]);

    const handleRemove = useCallback(() => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl(null);
        }
        setFileList([]);
        pushToast(
            <Message type="info">Image removed</Message>,
            { placement: 'topCenter', duration: 5000 }
        );
    }, [previewUrl, pushToast]);

    // Add handleServerError function before onSubmit
    const handleServerError = (responseData) => {
        if (typeof responseData.message === 'object') {
            // Format nested error messages from the server
            const errorMessages = Object.entries(responseData.message)
                .map(([key, messages]) => `${key}: ${messages.join(', ')}`)
                .join('\n');
            setServerError(errorMessages);
        } else {
            setServerError(responseData.message || 'Failed to create project');
        }
    };

    const onSubmit = async (formData) => {
        // Validation checks
        if (!formData.client_id && !clientId) {
            setError('client_id', {
                type: 'required',
                message: 'Please select a client'
            });
            return;
        }
        if (!formData.kam_id) {
            setError('kam_id', {
                type: 'required',
                message: 'Please select a Key Account Manager'
            });
            return;
        }

        setServerError(null);
        
        const formDataPayload = new FormData();
        formDataPayload.append('name', formData.name);
        formDataPayload.append('project_id', formData.project_id);
        formDataPayload.append('client_id', clientId || formData.client_id);
        formDataPayload.append('kam_id', formData.kam_id);
        if (fileList.length > 0) {
            formDataPayload.append('logo', fileList[0].blobFile);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/`, { 
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formDataPayload
            });

            const responseData = await response.json();

            if (response.status === 201) {
                setRemoveContainer(false);
                setShowSuccess(true);
                await setRefresh(Date.now());
                reset();
                setPreviewUrl(null);
                setFileList([]);

                setTimeout(() => {
                    if (panelRef.current) {
                        panelRef.current.tryCollapse();
                        setShowSuccess(false);
                        setShowCreateProject(false);
                        setTimeout(() => setRemoveContainer(true), 300);
                    }
                }, 3000);
            } else {
                handleServerError(responseData);
            }
        } catch (error) {
            setServerError(error.message || 'An unexpected error occurred');
        }
    };

    return (
        <CreatorAccordion>
            <CreatorAccordion.Panel 
                ref={panelRef}
                header="Create new project"
                initClosed={true}
            >
                <Container>
                    <GridLayout>
                        <FormSection>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Label htmlFor="name">Project name</Label>
                                    <StandardInput
                                        id="name"
                                        {...register("name", { 
                                            required: "Project name is required"
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors.name?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="project_id">Project ID</Label>
                                    <StandardInput
                                        id="project_id"
                                        {...register("project_id", { 
                                            required: "Project ID is required"
                                        })}
                                    />
                                    <ErrorMessage>
                                        {errors.project_id?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>

                                {!clientId && (
                                    <FormGroup>
                                        <Label htmlFor="client_id">Client</Label>
                                        <SelectPicker 
                                            id="client_id"
                                            searchable={false}
                                            cleanable={false}
                                            style={{minWidth: 300}}
                                            data={clients.map(client => ({
                                                label: client.name,
                                                value: client.id
                                            }))}
                                            value={watch('client_id')}
                                            onChange={(value) => {
                                                setValue('client_id', value, { shouldValidate: true });
                                                clearErrors('client_id');
                                            }}
                                            disabled={loading}
                                            placeholder={loading ? "Loading clients..." : "Select client"}
                                        />
                                        <ErrorMessage>
                                            {errors.client_id?.message || '\u00A0'}
                                        </ErrorMessage>
                                    </FormGroup>
                                )}

                                <FormGroup>
                                    <Label htmlFor="kam_id">Key Account Manager (KAM)</Label>
                                    <SelectPicker 
                                        id="kam_id"
                                        searchable={false}
                                        cleanable={false}
                                        style={{minWidth: 300}}
                                        data={kams.map(kam => ({
                                            label: kam.name,
                                            value: kam.id
                                        }))}
                                        value={watch('kam_id')}
                                        onChange={(value) => {
                                            setValue('kam_id', value, { shouldValidate: true });
                                            clearErrors('kam_id');
                                        }}
                                        disabled={loading}
                                        placeholder={loading ? "Loading KAMs..." : "Select KAM"}
                                    />
                                    <ErrorMessage>
                                        {errors.kam_id?.message || '\u00A0'}
                                    </ErrorMessage>
                                </FormGroup>
                            </form>
                        </FormSection>
                        <FormSection>
                            <ImageUploader
                                onFileSelect={handleFileChange}
                                onRemove={handleRemove}
                                previewUrl={previewUrl}
                                fileList={fileList}
                                uploading={uploading}
                                headerText="Project logo"
                            />
                        </FormSection>
                    </GridLayout>

                    <ButtonContainer>
                        <StandardButton onClick={handleSubmit(onSubmit)}>
                            Save project
                        </StandardButton>
                    </ButtonContainer>

                    {(serverError || showSuccess) && (
                        <SuccessContainer>
                            {serverError ? (
                                <ServerErrorMessage>{serverError}</ServerErrorMessage>
                            ) : (
                                <CheckmarkAnimation 
                                    show={showSuccess} 
                                    text="Project created successfully!"
                                />
                            )}
                        </SuccessContainer>
                    )}
                </Container>
            </CreatorAccordion.Panel>
        </CreatorAccordion>
    );
};

export default CreateProject;
