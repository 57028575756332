/**
 * Standard dropdown button component with navigation capabilities.
 * Features:
 * - Title with arrow indicator
 * - Click-outside detection for auto-closing
 * - Router-linked menu items
 */

import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { dropdownContainer, dropdownMenu, dropdownItem } from '../styles/DropdownButtonStyles';
import { Button, StyledArrowDownLineIcon, StyledArrowUpLineIcon } from './styled_sub_comps/DropdownButtonStyledSubComps';
import { Link } from 'react-router-dom';

/**
 * MenuItem component that acts as a router link and auto-closes dropdown when clicked
 */
const MenuItem = forwardRef(({ children, setIsOpen, ...props }, ref) => (
    <Link 
        css={dropdownItem} 
        ref={ref} 
        {...props} 
        onClick={(e) => {
            if (setIsOpen) setIsOpen(false);
            if (props.onClick) props.onClick(e);
        }}
    >
        {children}
    </Link>
));

/**
 * Main dropdown component that manages its own open/close state
 * @param {string} title - Text shown on the dropdown button
 * @param {React.ReactNode} children - MenuItem components to be rendered in dropdown
 */
const DropdownButton = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <div css={dropdownContainer} ref={dropdownRef}>
            <Button onClick={handleToggle} className={isOpen ? 'open' : ''}>
                {title} {isOpen ? <StyledArrowUpLineIcon /> : <StyledArrowDownLineIcon />}
            </Button>
            <div css={dropdownMenu} className={isOpen ? 'open' : ''}>
                {React.Children.map(children, child => 
                    child && typeof child.type !== 'string' 
                        ? React.cloneElement(child, { setIsOpen: setIsOpen || (() => {}) })
                        : child
                )}
            </div>
        </div>
    );
};

export default DropdownButton;
export { DropdownButton, MenuItem };